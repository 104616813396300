// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file analytics/v2/service.proto (package analytics.v2, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, protoInt64, Timestamp } from '@bufbuild/protobuf'
import {
  CompetitiveBenchmarkingData,
  Coordinate,
  CountData,
  DateRangeData,
  DateRangePixelsData,
  DateRangePixelsTotals,
  DateRangeTotals,
  EventType,
  GeoAdministrationLevel,
  GeoJson,
  Interval,
  OrgActivityData,
  Pagination,
  Product,
  RangeSummaryData,
  RawEventsCol,
  RawEventsData,
  ScatterPlotCoordinate,
  SummaryData,
  SummaryPixelsData,
  TopCitiesData,
  TopCodesData,
  TopCodesPixelsData,
  TopDaysData,
  TopDestinationsData,
  TopDevicesData,
  TopHoursData,
  TopLinksData,
  TopOperatingSystemsData,
  TopPagesData,
  TopPathsPixelsData,
  TopStatesData,
  TopTagsData,
  TopUTMCampaignsData,
  TopUTMReferrersData,
  TopUTMTermsData
} from './analytics_pb'

/**
 * Healthcheck
 *
 * @generated from message analytics.v2.HealthCheckRequest
 */
export class HealthCheckRequest extends Message<HealthCheckRequest> {
  constructor(data?: PartialMessage<HealthCheckRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.HealthCheckRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HealthCheckRequest {
    return new HealthCheckRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HealthCheckRequest {
    return new HealthCheckRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): HealthCheckRequest {
    return new HealthCheckRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: HealthCheckRequest | PlainMessage<HealthCheckRequest> | undefined,
    b: HealthCheckRequest | PlainMessage<HealthCheckRequest> | undefined
  ): boolean {
    return proto3.util.equals(HealthCheckRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.HealthCheckResponse
 */
export class HealthCheckResponse extends Message<HealthCheckResponse> {
  constructor(data?: PartialMessage<HealthCheckResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.HealthCheckResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HealthCheckResponse {
    return new HealthCheckResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HealthCheckResponse {
    return new HealthCheckResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): HealthCheckResponse {
    return new HealthCheckResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: HealthCheckResponse | PlainMessage<HealthCheckResponse> | undefined,
    b: HealthCheckResponse | PlainMessage<HealthCheckResponse> | undefined
  ): boolean {
    return proto3.util.equals(HealthCheckResponse, a, b)
  }
}

/**
 * Audience Insights
 * No params in request bc just need ithaca_id
 *
 * @generated from message analytics.v2.CompetitiveBenchmarkingRequest
 */
export class CompetitiveBenchmarkingRequest extends Message<CompetitiveBenchmarkingRequest> {
  constructor(data?: PartialMessage<CompetitiveBenchmarkingRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.CompetitiveBenchmarkingRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CompetitiveBenchmarkingRequest {
    return new CompetitiveBenchmarkingRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CompetitiveBenchmarkingRequest {
    return new CompetitiveBenchmarkingRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CompetitiveBenchmarkingRequest {
    return new CompetitiveBenchmarkingRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CompetitiveBenchmarkingRequest | PlainMessage<CompetitiveBenchmarkingRequest> | undefined,
    b: CompetitiveBenchmarkingRequest | PlainMessage<CompetitiveBenchmarkingRequest> | undefined
  ): boolean {
    return proto3.util.equals(CompetitiveBenchmarkingRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.CompetitiveBenchmarkingResponse
 */
export class CompetitiveBenchmarkingResponse extends Message<CompetitiveBenchmarkingResponse> {
  /**
   * @generated from field: analytics.v2.CompetitiveBenchmarkingData data = 1;
   */
  data?: CompetitiveBenchmarkingData

  constructor(data?: PartialMessage<CompetitiveBenchmarkingResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.CompetitiveBenchmarkingResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: CompetitiveBenchmarkingData }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CompetitiveBenchmarkingResponse {
    return new CompetitiveBenchmarkingResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CompetitiveBenchmarkingResponse {
    return new CompetitiveBenchmarkingResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CompetitiveBenchmarkingResponse {
    return new CompetitiveBenchmarkingResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CompetitiveBenchmarkingResponse | PlainMessage<CompetitiveBenchmarkingResponse> | undefined,
    b: CompetitiveBenchmarkingResponse | PlainMessage<CompetitiveBenchmarkingResponse> | undefined
  ): boolean {
    return proto3.util.equals(CompetitiveBenchmarkingResponse, a, b)
  }
}

/**
 * Range summary
 *
 * @generated from message analytics.v2.RangeSummaryRequest
 */
export class RangeSummaryRequest extends Message<RangeSummaryRequest> {
  /**
   * @generated from field: analytics.v2.Product product = 1;
   */
  product = Product.UNSPECIFIED

  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 2;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp

  /**
   * @generated from field: string timezone = 5;
   */
  timezone = ''

  /**
   * @generated from field: bool all_assets = 6;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 7;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 8;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 9;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 10;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 11;
   */
  tagName: string[] = []

  constructor(data?: PartialMessage<RangeSummaryRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.RangeSummaryRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    { no: 2, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 3, name: 'start_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'end_date', kind: 'message', T: Timestamp },
    { no: 5, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 11, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RangeSummaryRequest {
    return new RangeSummaryRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RangeSummaryRequest {
    return new RangeSummaryRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RangeSummaryRequest {
    return new RangeSummaryRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: RangeSummaryRequest | PlainMessage<RangeSummaryRequest> | undefined,
    b: RangeSummaryRequest | PlainMessage<RangeSummaryRequest> | undefined
  ): boolean {
    return proto3.util.equals(RangeSummaryRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.RangeSummaryResponse
 */
export class RangeSummaryResponse extends Message<RangeSummaryResponse> {
  /**
   * @generated from field: analytics.v2.RangeSummaryData data = 1;
   */
  data?: RangeSummaryData

  constructor(data?: PartialMessage<RangeSummaryResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.RangeSummaryResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: RangeSummaryData }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RangeSummaryResponse {
    return new RangeSummaryResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RangeSummaryResponse {
    return new RangeSummaryResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RangeSummaryResponse {
    return new RangeSummaryResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: RangeSummaryResponse | PlainMessage<RangeSummaryResponse> | undefined,
    b: RangeSummaryResponse | PlainMessage<RangeSummaryResponse> | undefined
  ): boolean {
    return proto3.util.equals(RangeSummaryResponse, a, b)
  }
}

/**
 * Summary
 *
 * @generated from message analytics.v2.SummaryRequest
 */
export class SummaryRequest extends Message<SummaryRequest> {
  /**
   * @generated from field: analytics.v2.Product product = 1;
   */
  product = Product.UNSPECIFIED

  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 2;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: string timezone = 3;
   */
  timezone = ''

  /**
   * @generated from field: bool all_assets = 4;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 5;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 6;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 7;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 8;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 9;
   */
  tagName: string[] = []

  constructor(data?: PartialMessage<SummaryRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.SummaryRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    { no: 2, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 3, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 9, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummaryRequest {
    return new SummaryRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryRequest {
    return new SummaryRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummaryRequest {
    return new SummaryRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: SummaryRequest | PlainMessage<SummaryRequest> | undefined,
    b: SummaryRequest | PlainMessage<SummaryRequest> | undefined
  ): boolean {
    return proto3.util.equals(SummaryRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.SummaryResponse
 */
export class SummaryResponse extends Message<SummaryResponse> {
  /**
   * @generated from field: analytics.v2.SummaryData data = 1;
   */
  data?: SummaryData

  constructor(data?: PartialMessage<SummaryResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.SummaryResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: SummaryData }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummaryResponse {
    return new SummaryResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryResponse {
    return new SummaryResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummaryResponse {
    return new SummaryResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: SummaryResponse | PlainMessage<SummaryResponse> | undefined,
    b: SummaryResponse | PlainMessage<SummaryResponse> | undefined
  ): boolean {
    return proto3.util.equals(SummaryResponse, a, b)
  }
}

/**
 * @generated from message analytics.v2.SummaryPixelsRequest
 */
export class SummaryPixelsRequest extends Message<SummaryPixelsRequest> {
  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 1;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 3;
   */
  endDate?: Timestamp

  /**
   * @generated from field: string timezone = 4;
   */
  timezone = ''

  /**
   * @generated from field: bool all_assets = 5;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 6;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 7;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 8;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 9;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 10;
   */
  tagName: string[] = []

  /**
   * @generated from field: repeated string app_id = 11;
   */
  appId: string[] = []

  constructor(data?: PartialMessage<SummaryPixelsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.SummaryPixelsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 2, name: 'start_date', kind: 'message', T: Timestamp },
    { no: 3, name: 'end_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: 'app_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummaryPixelsRequest {
    return new SummaryPixelsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryPixelsRequest {
    return new SummaryPixelsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SummaryPixelsRequest {
    return new SummaryPixelsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: SummaryPixelsRequest | PlainMessage<SummaryPixelsRequest> | undefined,
    b: SummaryPixelsRequest | PlainMessage<SummaryPixelsRequest> | undefined
  ): boolean {
    return proto3.util.equals(SummaryPixelsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.SummaryPixelsResponse
 */
export class SummaryPixelsResponse extends Message<SummaryPixelsResponse> {
  /**
   * @generated from field: analytics.v2.SummaryPixelsData data = 1;
   */
  data?: SummaryPixelsData

  constructor(data?: PartialMessage<SummaryPixelsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.SummaryPixelsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: SummaryPixelsData }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): SummaryPixelsResponse {
    return new SummaryPixelsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryPixelsResponse {
    return new SummaryPixelsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): SummaryPixelsResponse {
    return new SummaryPixelsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: SummaryPixelsResponse | PlainMessage<SummaryPixelsResponse> | undefined,
    b: SummaryPixelsResponse | PlainMessage<SummaryPixelsResponse> | undefined
  ): boolean {
    return proto3.util.equals(SummaryPixelsResponse, a, b)
  }
}

/**
 * Count
 *
 * @generated from message analytics.v2.CountRequest
 */
export class CountRequest extends Message<CountRequest> {
  /**
   * @generated from field: analytics.v2.Product product = 1;
   */
  product = Product.UNSPECIFIED

  /**
   * @generated from field: repeated string asset_id = 2;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 3;
   */
  pageId: string[] = []

  /**
   * @generated from field: bool all_assets = 4;
   */
  allAssets = false

  /**
   * @generated from field: repeated int32 directory_id = 5;
   */
  directoryId: number[] = []

  constructor(data?: PartialMessage<CountRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.CountRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    { no: 2, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountRequest {
    return new CountRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountRequest {
    return new CountRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CountRequest {
    return new CountRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: CountRequest | PlainMessage<CountRequest> | undefined,
    b: CountRequest | PlainMessage<CountRequest> | undefined
  ): boolean {
    return proto3.util.equals(CountRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.CountResponse
 */
export class CountResponse extends Message<CountResponse> {
  /**
   * @generated from field: analytics.v2.CountData data = 1;
   */
  data?: CountData

  constructor(data?: PartialMessage<CountResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.CountResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: CountData }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountResponse {
    return new CountResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountResponse {
    return new CountResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CountResponse {
    return new CountResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: CountResponse | PlainMessage<CountResponse> | undefined,
    b: CountResponse | PlainMessage<CountResponse> | undefined
  ): boolean {
    return proto3.util.equals(CountResponse, a, b)
  }
}

/**
 * Raw events
 *
 * @generated from message analytics.v2.SortByConfig
 */
export class SortByConfig extends Message<SortByConfig> {
  /**
   * @generated from field: analytics.v2.RawEventsCol field = 1;
   */
  field = RawEventsCol.COL_UNSPECIFIED

  /**
   * @generated from field: string direction = 2;
   */
  direction = ''

  constructor(data?: PartialMessage<SortByConfig>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.SortByConfig'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'field', kind: 'enum', T: proto3.getEnumType(RawEventsCol) },
    { no: 2, name: 'direction', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SortByConfig {
    return new SortByConfig().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SortByConfig {
    return new SortByConfig().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SortByConfig {
    return new SortByConfig().fromJsonString(jsonString, options)
  }

  static equals(
    a: SortByConfig | PlainMessage<SortByConfig> | undefined,
    b: SortByConfig | PlainMessage<SortByConfig> | undefined
  ): boolean {
    return proto3.util.equals(SortByConfig, a, b)
  }
}

/**
 * @generated from message analytics.v2.RawEventsDisplayConfig
 */
export class RawEventsDisplayConfig extends Message<RawEventsDisplayConfig> {
  /**
   * @generated from field: repeated analytics.v2.RawEventsCol columns = 1;
   */
  columns: RawEventsCol[] = []

  /**
   * @generated from field: repeated analytics.v2.SortByConfig sort_by = 2;
   */
  sortBy: SortByConfig[] = []

  constructor(data?: PartialMessage<RawEventsDisplayConfig>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.RawEventsDisplayConfig'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'columns', kind: 'enum', T: proto3.getEnumType(RawEventsCol), repeated: true },
    { no: 2, name: 'sort_by', kind: 'message', T: SortByConfig, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): RawEventsDisplayConfig {
    return new RawEventsDisplayConfig().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): RawEventsDisplayConfig {
    return new RawEventsDisplayConfig().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): RawEventsDisplayConfig {
    return new RawEventsDisplayConfig().fromJsonString(jsonString, options)
  }

  static equals(
    a: RawEventsDisplayConfig | PlainMessage<RawEventsDisplayConfig> | undefined,
    b: RawEventsDisplayConfig | PlainMessage<RawEventsDisplayConfig> | undefined
  ): boolean {
    return proto3.util.equals(RawEventsDisplayConfig, a, b)
  }
}

/**
 * @generated from message analytics.v2.RawEventsRequest
 */
export class RawEventsRequest extends Message<RawEventsRequest> {
  /**
   * @generated from field: repeated analytics.v2.Product product = 1;
   */
  product: Product[] = []

  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 2;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp

  /**
   * @generated from field: string timezone = 5;
   */
  timezone = ''

  /**
   * @generated from field: bool all_assets = 6;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 7;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 8;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 9;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 10;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 11;
   */
  tagName: string[] = []

  /**
   * @generated from field: int64 page_size = 12;
   */
  pageSize = protoInt64.zero

  /**
   * @generated from field: int64 page_num = 13;
   */
  pageNum = protoInt64.zero

  /**
   * @generated from field: int64 row_limit = 14;
   */
  rowLimit = protoInt64.zero

  /**
   * @generated from field: analytics.v2.RawEventsDisplayConfig raw_events_display_config = 15;
   */
  rawEventsDisplayConfig?: RawEventsDisplayConfig

  /**
   * @generated from field: repeated string app_id = 16;
   */
  appId: string[] = []

  constructor(data?: PartialMessage<RawEventsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.RawEventsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product), repeated: true },
    { no: 2, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 3, name: 'start_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'end_date', kind: 'message', T: Timestamp },
    { no: 5, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 11, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: 'page_size', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: 'page_num', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: 'row_limit', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: 'raw_events_display_config', kind: 'message', T: RawEventsDisplayConfig },
    { no: 16, name: 'app_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RawEventsRequest {
    return new RawEventsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RawEventsRequest {
    return new RawEventsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RawEventsRequest {
    return new RawEventsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: RawEventsRequest | PlainMessage<RawEventsRequest> | undefined,
    b: RawEventsRequest | PlainMessage<RawEventsRequest> | undefined
  ): boolean {
    return proto3.util.equals(RawEventsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.RawEventsResponse
 */
export class RawEventsResponse extends Message<RawEventsResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.RawEventsCol columns = 2;
   */
  columns: RawEventsCol[] = []

  /**
   * @generated from field: repeated analytics.v2.RawEventsData data = 3;
   */
  data: RawEventsData[] = []

  constructor(data?: PartialMessage<RawEventsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.RawEventsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'columns', kind: 'enum', T: proto3.getEnumType(RawEventsCol), repeated: true },
    { no: 3, name: 'data', kind: 'message', T: RawEventsData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RawEventsResponse {
    return new RawEventsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RawEventsResponse {
    return new RawEventsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RawEventsResponse {
    return new RawEventsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: RawEventsResponse | PlainMessage<RawEventsResponse> | undefined,
    b: RawEventsResponse | PlainMessage<RawEventsResponse> | undefined
  ): boolean {
    return proto3.util.equals(RawEventsResponse, a, b)
  }
}

/**
 * Top
 *
 * @generated from message analytics.v2.TopRequest
 */
export class TopRequest extends Message<TopRequest> {
  /**
   * @generated from field: analytics.v2.Product product = 1;
   */
  product = Product.UNSPECIFIED

  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 2;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp

  /**
   * @generated from field: string timezone = 5;
   */
  timezone = ''

  /**
   * @generated from field: bool all_assets = 6;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 7;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 8;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 9;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 10;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 11;
   */
  tagName: string[] = []

  /**
   * @generated from field: int64 page_size = 12;
   */
  pageSize = protoInt64.zero

  /**
   * @generated from field: int64 page_num = 13;
   */
  pageNum = protoInt64.zero

  /**
   * @generated from field: repeated string app_id = 14;
   */
  appId: string[] = []

  constructor(data?: PartialMessage<TopRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    { no: 2, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 3, name: 'start_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'end_date', kind: 'message', T: Timestamp },
    { no: 5, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 11, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: 'page_size', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: 'page_num', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: 'app_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopRequest {
    return new TopRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopRequest {
    return new TopRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopRequest {
    return new TopRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopRequest | PlainMessage<TopRequest> | undefined,
    b: TopRequest | PlainMessage<TopRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopRequest, a, b)
  }
}

/**
 * Top Codes
 *
 * @generated from message analytics.v2.TopCodesRequest
 */
export class TopCodesRequest extends Message<TopCodesRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopCodesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopCodesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopCodesRequest {
    return new TopCodesRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopCodesRequest {
    return new TopCodesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopCodesRequest {
    return new TopCodesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopCodesRequest | PlainMessage<TopCodesRequest> | undefined,
    b: TopCodesRequest | PlainMessage<TopCodesRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopCodesRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopCodesResponse
 */
export class TopCodesResponse extends Message<TopCodesResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopCodesData data = 2;
   */
  data: TopCodesData[] = []

  constructor(data?: PartialMessage<TopCodesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopCodesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopCodesData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopCodesResponse {
    return new TopCodesResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopCodesResponse {
    return new TopCodesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopCodesResponse {
    return new TopCodesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopCodesResponse | PlainMessage<TopCodesResponse> | undefined,
    b: TopCodesResponse | PlainMessage<TopCodesResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopCodesResponse, a, b)
  }
}

/**
 * Top Codes Pixels
 *
 * @generated from message analytics.v2.TopCodesPixelsRequest
 */
export class TopCodesPixelsRequest extends Message<TopCodesPixelsRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopCodesPixelsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopCodesPixelsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopCodesPixelsRequest {
    return new TopCodesPixelsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopCodesPixelsRequest {
    return new TopCodesPixelsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopCodesPixelsRequest {
    return new TopCodesPixelsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopCodesPixelsRequest | PlainMessage<TopCodesPixelsRequest> | undefined,
    b: TopCodesPixelsRequest | PlainMessage<TopCodesPixelsRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopCodesPixelsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopCodesPixelsResponse
 */
export class TopCodesPixelsResponse extends Message<TopCodesPixelsResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopCodesPixelsData data = 2;
   */
  data: TopCodesPixelsData[] = []

  constructor(data?: PartialMessage<TopCodesPixelsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopCodesPixelsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopCodesPixelsData, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopCodesPixelsResponse {
    return new TopCodesPixelsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopCodesPixelsResponse {
    return new TopCodesPixelsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopCodesPixelsResponse {
    return new TopCodesPixelsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopCodesPixelsResponse | PlainMessage<TopCodesPixelsResponse> | undefined,
    b: TopCodesPixelsResponse | PlainMessage<TopCodesPixelsResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopCodesPixelsResponse, a, b)
  }
}

/**
 * Top Paths Pixels
 *
 * @generated from message analytics.v2.TopPathsPixelsRequest
 */
export class TopPathsPixelsRequest extends Message<TopPathsPixelsRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopPathsPixelsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopPathsPixelsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopPathsPixelsRequest {
    return new TopPathsPixelsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopPathsPixelsRequest {
    return new TopPathsPixelsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopPathsPixelsRequest {
    return new TopPathsPixelsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopPathsPixelsRequest | PlainMessage<TopPathsPixelsRequest> | undefined,
    b: TopPathsPixelsRequest | PlainMessage<TopPathsPixelsRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopPathsPixelsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopPathsPixelsResponse
 */
export class TopPathsPixelsResponse extends Message<TopPathsPixelsResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopPathsPixelsData data = 2;
   */
  data: TopPathsPixelsData[] = []

  constructor(data?: PartialMessage<TopPathsPixelsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopPathsPixelsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopPathsPixelsData, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopPathsPixelsResponse {
    return new TopPathsPixelsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopPathsPixelsResponse {
    return new TopPathsPixelsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopPathsPixelsResponse {
    return new TopPathsPixelsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopPathsPixelsResponse | PlainMessage<TopPathsPixelsResponse> | undefined,
    b: TopPathsPixelsResponse | PlainMessage<TopPathsPixelsResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopPathsPixelsResponse, a, b)
  }
}

/**
 * Top Destinations
 *
 * @generated from message analytics.v2.TopDestinationsRequest
 */
export class TopDestinationsRequest extends Message<TopDestinationsRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopDestinationsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopDestinationsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopDestinationsRequest {
    return new TopDestinationsRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopDestinationsRequest {
    return new TopDestinationsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopDestinationsRequest {
    return new TopDestinationsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopDestinationsRequest | PlainMessage<TopDestinationsRequest> | undefined,
    b: TopDestinationsRequest | PlainMessage<TopDestinationsRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopDestinationsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopDestinationsResponse
 */
export class TopDestinationsResponse extends Message<TopDestinationsResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopDestinationsData data = 2;
   */
  data: TopDestinationsData[] = []

  constructor(data?: PartialMessage<TopDestinationsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopDestinationsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopDestinationsData, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopDestinationsResponse {
    return new TopDestinationsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopDestinationsResponse {
    return new TopDestinationsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopDestinationsResponse {
    return new TopDestinationsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopDestinationsResponse | PlainMessage<TopDestinationsResponse> | undefined,
    b: TopDestinationsResponse | PlainMessage<TopDestinationsResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopDestinationsResponse, a, b)
  }
}

/**
 * Top Pages
 *
 * @generated from message analytics.v2.TopPagesRequest
 */
export class TopPagesRequest extends Message<TopPagesRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopPagesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopPagesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopPagesRequest {
    return new TopPagesRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopPagesRequest {
    return new TopPagesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopPagesRequest {
    return new TopPagesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopPagesRequest | PlainMessage<TopPagesRequest> | undefined,
    b: TopPagesRequest | PlainMessage<TopPagesRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopPagesRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopPagesResponse
 */
export class TopPagesResponse extends Message<TopPagesResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopPagesData data = 2;
   */
  data: TopPagesData[] = []

  constructor(data?: PartialMessage<TopPagesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopPagesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopPagesData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopPagesResponse {
    return new TopPagesResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopPagesResponse {
    return new TopPagesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopPagesResponse {
    return new TopPagesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopPagesResponse | PlainMessage<TopPagesResponse> | undefined,
    b: TopPagesResponse | PlainMessage<TopPagesResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopPagesResponse, a, b)
  }
}

/**
 * Top Links
 *
 * @generated from message analytics.v2.TopLinksRequest
 */
export class TopLinksRequest extends Message<TopLinksRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopLinksRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopLinksRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopLinksRequest {
    return new TopLinksRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopLinksRequest {
    return new TopLinksRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopLinksRequest {
    return new TopLinksRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopLinksRequest | PlainMessage<TopLinksRequest> | undefined,
    b: TopLinksRequest | PlainMessage<TopLinksRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopLinksRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopLinksResponse
 */
export class TopLinksResponse extends Message<TopLinksResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopLinksData data = 2;
   */
  data: TopLinksData[] = []

  constructor(data?: PartialMessage<TopLinksResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopLinksResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopLinksData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopLinksResponse {
    return new TopLinksResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopLinksResponse {
    return new TopLinksResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopLinksResponse {
    return new TopLinksResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopLinksResponse | PlainMessage<TopLinksResponse> | undefined,
    b: TopLinksResponse | PlainMessage<TopLinksResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopLinksResponse, a, b)
  }
}

/**
 * Top UTM Campaigns
 *
 * @generated from message analytics.v2.TopUTMCampaignsRequest
 */
export class TopUTMCampaignsRequest extends Message<TopUTMCampaignsRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopUTMCampaignsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopUTMCampaignsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopUTMCampaignsRequest {
    return new TopUTMCampaignsRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopUTMCampaignsRequest {
    return new TopUTMCampaignsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopUTMCampaignsRequest {
    return new TopUTMCampaignsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopUTMCampaignsRequest | PlainMessage<TopUTMCampaignsRequest> | undefined,
    b: TopUTMCampaignsRequest | PlainMessage<TopUTMCampaignsRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopUTMCampaignsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopUTMCampaignsResponse
 */
export class TopUTMCampaignsResponse extends Message<TopUTMCampaignsResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopUTMCampaignsData data = 2;
   */
  data: TopUTMCampaignsData[] = []

  constructor(data?: PartialMessage<TopUTMCampaignsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopUTMCampaignsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopUTMCampaignsData, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopUTMCampaignsResponse {
    return new TopUTMCampaignsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopUTMCampaignsResponse {
    return new TopUTMCampaignsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopUTMCampaignsResponse {
    return new TopUTMCampaignsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopUTMCampaignsResponse | PlainMessage<TopUTMCampaignsResponse> | undefined,
    b: TopUTMCampaignsResponse | PlainMessage<TopUTMCampaignsResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopUTMCampaignsResponse, a, b)
  }
}

/**
 * Top UTM Terms
 *
 * @generated from message analytics.v2.TopUTMTermsRequest
 */
export class TopUTMTermsRequest extends Message<TopUTMTermsRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopUTMTermsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopUTMTermsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopUTMTermsRequest {
    return new TopUTMTermsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopUTMTermsRequest {
    return new TopUTMTermsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopUTMTermsRequest {
    return new TopUTMTermsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopUTMTermsRequest | PlainMessage<TopUTMTermsRequest> | undefined,
    b: TopUTMTermsRequest | PlainMessage<TopUTMTermsRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopUTMTermsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopUTMTermsResponse
 */
export class TopUTMTermsResponse extends Message<TopUTMTermsResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopUTMTermsData data = 2;
   */
  data: TopUTMTermsData[] = []

  constructor(data?: PartialMessage<TopUTMTermsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopUTMTermsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopUTMTermsData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopUTMTermsResponse {
    return new TopUTMTermsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopUTMTermsResponse {
    return new TopUTMTermsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopUTMTermsResponse {
    return new TopUTMTermsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopUTMTermsResponse | PlainMessage<TopUTMTermsResponse> | undefined,
    b: TopUTMTermsResponse | PlainMessage<TopUTMTermsResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopUTMTermsResponse, a, b)
  }
}

/**
 * Top UTM Referrers
 *
 * @generated from message analytics.v2.TopUTMReferrersRequest
 */
export class TopUTMReferrersRequest extends Message<TopUTMReferrersRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopUTMReferrersRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopUTMReferrersRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopUTMReferrersRequest {
    return new TopUTMReferrersRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopUTMReferrersRequest {
    return new TopUTMReferrersRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopUTMReferrersRequest {
    return new TopUTMReferrersRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopUTMReferrersRequest | PlainMessage<TopUTMReferrersRequest> | undefined,
    b: TopUTMReferrersRequest | PlainMessage<TopUTMReferrersRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopUTMReferrersRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopUTMReferrersResponse
 */
export class TopUTMReferrersResponse extends Message<TopUTMReferrersResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopUTMReferrersData data = 2;
   */
  data: TopUTMReferrersData[] = []

  constructor(data?: PartialMessage<TopUTMReferrersResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopUTMReferrersResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopUTMReferrersData, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopUTMReferrersResponse {
    return new TopUTMReferrersResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopUTMReferrersResponse {
    return new TopUTMReferrersResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopUTMReferrersResponse {
    return new TopUTMReferrersResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopUTMReferrersResponse | PlainMessage<TopUTMReferrersResponse> | undefined,
    b: TopUTMReferrersResponse | PlainMessage<TopUTMReferrersResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopUTMReferrersResponse, a, b)
  }
}

/**
 * Top Cities
 *
 * @generated from message analytics.v2.TopCitiesRequest
 */
export class TopCitiesRequest extends Message<TopCitiesRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopCitiesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopCitiesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopCitiesRequest {
    return new TopCitiesRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopCitiesRequest {
    return new TopCitiesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopCitiesRequest {
    return new TopCitiesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopCitiesRequest | PlainMessage<TopCitiesRequest> | undefined,
    b: TopCitiesRequest | PlainMessage<TopCitiesRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopCitiesRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopCitiesResponse
 */
export class TopCitiesResponse extends Message<TopCitiesResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopCitiesData data = 2;
   */
  data: TopCitiesData[] = []

  constructor(data?: PartialMessage<TopCitiesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopCitiesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopCitiesData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopCitiesResponse {
    return new TopCitiesResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopCitiesResponse {
    return new TopCitiesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopCitiesResponse {
    return new TopCitiesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopCitiesResponse | PlainMessage<TopCitiesResponse> | undefined,
    b: TopCitiesResponse | PlainMessage<TopCitiesResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopCitiesResponse, a, b)
  }
}

/**
 * Top States
 *
 * @generated from message analytics.v2.TopStatesRequest
 */
export class TopStatesRequest extends Message<TopStatesRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopStatesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopStatesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopStatesRequest {
    return new TopStatesRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopStatesRequest {
    return new TopStatesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopStatesRequest {
    return new TopStatesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopStatesRequest | PlainMessage<TopStatesRequest> | undefined,
    b: TopStatesRequest | PlainMessage<TopStatesRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopStatesRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopStatesResponse
 */
export class TopStatesResponse extends Message<TopStatesResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopStatesData data = 2;
   */
  data: TopStatesData[] = []

  constructor(data?: PartialMessage<TopStatesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopStatesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopStatesData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopStatesResponse {
    return new TopStatesResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopStatesResponse {
    return new TopStatesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopStatesResponse {
    return new TopStatesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopStatesResponse | PlainMessage<TopStatesResponse> | undefined,
    b: TopStatesResponse | PlainMessage<TopStatesResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopStatesResponse, a, b)
  }
}

/**
 * Top Days
 *
 * @generated from message analytics.v2.TopDaysRequest
 */
export class TopDaysRequest extends Message<TopDaysRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopDaysRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopDaysRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopDaysRequest {
    return new TopDaysRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopDaysRequest {
    return new TopDaysRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopDaysRequest {
    return new TopDaysRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopDaysRequest | PlainMessage<TopDaysRequest> | undefined,
    b: TopDaysRequest | PlainMessage<TopDaysRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopDaysRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopDaysResponse
 */
export class TopDaysResponse extends Message<TopDaysResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopDaysData data = 2;
   */
  data: TopDaysData[] = []

  constructor(data?: PartialMessage<TopDaysResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopDaysResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopDaysData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopDaysResponse {
    return new TopDaysResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopDaysResponse {
    return new TopDaysResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopDaysResponse {
    return new TopDaysResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopDaysResponse | PlainMessage<TopDaysResponse> | undefined,
    b: TopDaysResponse | PlainMessage<TopDaysResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopDaysResponse, a, b)
  }
}

/**
 * Top Hours
 *
 * @generated from message analytics.v2.TopHoursRequest
 */
export class TopHoursRequest extends Message<TopHoursRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopHoursRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopHoursRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopHoursRequest {
    return new TopHoursRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopHoursRequest {
    return new TopHoursRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopHoursRequest {
    return new TopHoursRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopHoursRequest | PlainMessage<TopHoursRequest> | undefined,
    b: TopHoursRequest | PlainMessage<TopHoursRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopHoursRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopHoursResponse
 */
export class TopHoursResponse extends Message<TopHoursResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopHoursData data = 2;
   */
  data: TopHoursData[] = []

  constructor(data?: PartialMessage<TopHoursResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopHoursResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopHoursData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopHoursResponse {
    return new TopHoursResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopHoursResponse {
    return new TopHoursResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopHoursResponse {
    return new TopHoursResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopHoursResponse | PlainMessage<TopHoursResponse> | undefined,
    b: TopHoursResponse | PlainMessage<TopHoursResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopHoursResponse, a, b)
  }
}

/**
 * Top Devices
 *
 * @generated from message analytics.v2.TopDevicesRequest
 */
export class TopDevicesRequest extends Message<TopDevicesRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopDevicesRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopDevicesRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopDevicesRequest {
    return new TopDevicesRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopDevicesRequest {
    return new TopDevicesRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopDevicesRequest {
    return new TopDevicesRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopDevicesRequest | PlainMessage<TopDevicesRequest> | undefined,
    b: TopDevicesRequest | PlainMessage<TopDevicesRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopDevicesRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopDevicesResponse
 */
export class TopDevicesResponse extends Message<TopDevicesResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopDevicesData data = 2;
   */
  data: TopDevicesData[] = []

  constructor(data?: PartialMessage<TopDevicesResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopDevicesResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopDevicesData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopDevicesResponse {
    return new TopDevicesResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopDevicesResponse {
    return new TopDevicesResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopDevicesResponse {
    return new TopDevicesResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopDevicesResponse | PlainMessage<TopDevicesResponse> | undefined,
    b: TopDevicesResponse | PlainMessage<TopDevicesResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopDevicesResponse, a, b)
  }
}

/**
 * Top Operating Systems
 *
 * @generated from message analytics.v2.TopOperatingSystemsRequest
 */
export class TopOperatingSystemsRequest extends Message<TopOperatingSystemsRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopOperatingSystemsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopOperatingSystemsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopOperatingSystemsRequest {
    return new TopOperatingSystemsRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopOperatingSystemsRequest {
    return new TopOperatingSystemsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopOperatingSystemsRequest {
    return new TopOperatingSystemsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopOperatingSystemsRequest | PlainMessage<TopOperatingSystemsRequest> | undefined,
    b: TopOperatingSystemsRequest | PlainMessage<TopOperatingSystemsRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopOperatingSystemsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopOperatingSystemsResponse
 */
export class TopOperatingSystemsResponse extends Message<TopOperatingSystemsResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopOperatingSystemsData data = 2;
   */
  data: TopOperatingSystemsData[] = []

  constructor(data?: PartialMessage<TopOperatingSystemsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopOperatingSystemsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopOperatingSystemsData, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopOperatingSystemsResponse {
    return new TopOperatingSystemsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopOperatingSystemsResponse {
    return new TopOperatingSystemsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopOperatingSystemsResponse {
    return new TopOperatingSystemsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopOperatingSystemsResponse | PlainMessage<TopOperatingSystemsResponse> | undefined,
    b: TopOperatingSystemsResponse | PlainMessage<TopOperatingSystemsResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopOperatingSystemsResponse, a, b)
  }
}

/**
 * Top Tags
 *
 * @generated from message analytics.v2.TopTagsRequest
 */
export class TopTagsRequest extends Message<TopTagsRequest> {
  /**
   * @generated from field: analytics.v2.TopRequest top_request = 1;
   */
  topRequest?: TopRequest

  constructor(data?: PartialMessage<TopTagsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopTagsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'top_request', kind: 'message', T: TopRequest }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopTagsRequest {
    return new TopTagsRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopTagsRequest {
    return new TopTagsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopTagsRequest {
    return new TopTagsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopTagsRequest | PlainMessage<TopTagsRequest> | undefined,
    b: TopTagsRequest | PlainMessage<TopTagsRequest> | undefined
  ): boolean {
    return proto3.util.equals(TopTagsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.TopTagsResponse
 */
export class TopTagsResponse extends Message<TopTagsResponse> {
  /**
   * @generated from field: analytics.v2.Pagination pagination = 1;
   */
  pagination?: Pagination

  /**
   * @generated from field: repeated analytics.v2.TopTagsData data = 2;
   */
  data: TopTagsData[] = []

  constructor(data?: PartialMessage<TopTagsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopTagsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'pagination', kind: 'message', T: Pagination },
    { no: 2, name: 'data', kind: 'message', T: TopTagsData, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopTagsResponse {
    return new TopTagsResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopTagsResponse {
    return new TopTagsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopTagsResponse {
    return new TopTagsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopTagsResponse | PlainMessage<TopTagsResponse> | undefined,
    b: TopTagsResponse | PlainMessage<TopTagsResponse> | undefined
  ): boolean {
    return proto3.util.equals(TopTagsResponse, a, b)
  }
}

/**
 * Date Range
 *
 * @generated from message analytics.v2.DateRangeRequest
 */
export class DateRangeRequest extends Message<DateRangeRequest> {
  /**
   * @generated from field: analytics.v2.Product product = 1;
   */
  product = Product.UNSPECIFIED

  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 2;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp

  /**
   * @generated from field: analytics.v2.Interval interval = 5;
   */
  interval = Interval.UNSPECIFIED

  /**
   * @generated from field: string timezone = 6;
   */
  timezone = ''

  /**
   * @generated from field: bool all_assets = 7;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 8;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 9;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 10;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 11;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 12;
   */
  tagName: string[] = []

  constructor(data?: PartialMessage<DateRangeRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DateRangeRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    { no: 2, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 3, name: 'start_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'end_date', kind: 'message', T: Timestamp },
    { no: 5, name: 'interval', kind: 'enum', T: proto3.getEnumType(Interval) },
    { no: 6, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 12, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateRangeRequest {
    return new DateRangeRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateRangeRequest {
    return new DateRangeRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DateRangeRequest {
    return new DateRangeRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRangeRequest | PlainMessage<DateRangeRequest> | undefined,
    b: DateRangeRequest | PlainMessage<DateRangeRequest> | undefined
  ): boolean {
    return proto3.util.equals(DateRangeRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.DateRangeResponse
 */
export class DateRangeResponse extends Message<DateRangeResponse> {
  /**
   * @generated from field: repeated analytics.v2.DateRangeData data = 1;
   */
  data: DateRangeData[] = []

  /**
   * @generated from field: analytics.v2.DateRangeTotals totals = 2;
   */
  totals?: DateRangeTotals

  constructor(data?: PartialMessage<DateRangeResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DateRangeResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: DateRangeData, repeated: true },
    { no: 2, name: 'totals', kind: 'message', T: DateRangeTotals }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateRangeResponse {
    return new DateRangeResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateRangeResponse {
    return new DateRangeResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DateRangeResponse {
    return new DateRangeResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRangeResponse | PlainMessage<DateRangeResponse> | undefined,
    b: DateRangeResponse | PlainMessage<DateRangeResponse> | undefined
  ): boolean {
    return proto3.util.equals(DateRangeResponse, a, b)
  }
}

/**
 * Date Range Pixels
 *
 * @generated from message analytics.v2.DateRangePixelsRequest
 */
export class DateRangePixelsRequest extends Message<DateRangePixelsRequest> {
  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 1;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 2;
   */
  startDate?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 3;
   */
  endDate?: Timestamp

  /**
   * @generated from field: analytics.v2.Interval interval = 4;
   */
  interval = Interval.UNSPECIFIED

  /**
   * @generated from field: string timezone = 5;
   */
  timezone = ''

  /**
   * @generated from field: bool all_assets = 6;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 7;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 8;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 9;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 10;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 11;
   */
  tagName: string[] = []

  /**
   * @generated from field: repeated string app_id = 12;
   */
  appId: string[] = []

  constructor(data?: PartialMessage<DateRangePixelsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DateRangePixelsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 2, name: 'start_date', kind: 'message', T: Timestamp },
    { no: 3, name: 'end_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'interval', kind: 'enum', T: proto3.getEnumType(Interval) },
    { no: 5, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 11, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 12, name: 'app_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DateRangePixelsRequest {
    return new DateRangePixelsRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DateRangePixelsRequest {
    return new DateRangePixelsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DateRangePixelsRequest {
    return new DateRangePixelsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRangePixelsRequest | PlainMessage<DateRangePixelsRequest> | undefined,
    b: DateRangePixelsRequest | PlainMessage<DateRangePixelsRequest> | undefined
  ): boolean {
    return proto3.util.equals(DateRangePixelsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.DateRangePixelsResponse
 */
export class DateRangePixelsResponse extends Message<DateRangePixelsResponse> {
  /**
   * @generated from field: repeated analytics.v2.DateRangePixelsData data = 1;
   */
  data: DateRangePixelsData[] = []

  /**
   * @generated from field: analytics.v2.DateRangePixelsTotals totals = 2;
   */
  totals?: DateRangePixelsTotals

  constructor(data?: PartialMessage<DateRangePixelsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DateRangePixelsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: DateRangePixelsData, repeated: true },
    { no: 2, name: 'totals', kind: 'message', T: DateRangePixelsTotals }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DateRangePixelsResponse {
    return new DateRangePixelsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DateRangePixelsResponse {
    return new DateRangePixelsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DateRangePixelsResponse {
    return new DateRangePixelsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRangePixelsResponse | PlainMessage<DateRangePixelsResponse> | undefined,
    b: DateRangePixelsResponse | PlainMessage<DateRangePixelsResponse> | undefined
  ): boolean {
    return proto3.util.equals(DateRangePixelsResponse, a, b)
  }
}

/**
 * Download, works with pixels
 *
 * @generated from message analytics.v2.DownloadRequest
 */
export class DownloadRequest extends Message<DownloadRequest> {
  /**
   * @generated from field: analytics.v2.Product product = 1;
   */
  product = Product.UNSPECIFIED

  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 2;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp

  /**
   * @generated from field: analytics.v2.Interval interval = 5;
   */
  interval = Interval.UNSPECIFIED

  /**
   * @generated from field: string timezone = 6;
   */
  timezone = ''

  /**
   * @generated from field: bool all_assets = 7;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 8;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 9;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 10;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 11;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 12;
   */
  tagName: string[] = []

  constructor(data?: PartialMessage<DownloadRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DownloadRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    { no: 2, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 3, name: 'start_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'end_date', kind: 'message', T: Timestamp },
    { no: 5, name: 'interval', kind: 'enum', T: proto3.getEnumType(Interval) },
    { no: 6, name: 'timezone', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 12, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadRequest {
    return new DownloadRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadRequest {
    return new DownloadRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadRequest {
    return new DownloadRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DownloadRequest | PlainMessage<DownloadRequest> | undefined,
    b: DownloadRequest | PlainMessage<DownloadRequest> | undefined
  ): boolean {
    return proto3.util.equals(DownloadRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.DownloadResponse
 */
export class DownloadResponse extends Message<DownloadResponse> {
  /**
   * @generated from field: bytes data = 1;
   */
  data = new Uint8Array(0)

  constructor(data?: PartialMessage<DownloadResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DownloadResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'scalar', T: 12 /* ScalarType.BYTES */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadResponse {
    return new DownloadResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadResponse {
    return new DownloadResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadResponse {
    return new DownloadResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DownloadResponse | PlainMessage<DownloadResponse> | undefined,
    b: DownloadResponse | PlainMessage<DownloadResponse> | undefined
  ): boolean {
    return proto3.util.equals(DownloadResponse, a, b)
  }
}

/**
 * Raw events download, works with pixels
 *
 * @generated from message analytics.v2.DownloadRawEventsRequest
 */
export class DownloadRawEventsRequest extends Message<DownloadRawEventsRequest> {
  /**
   * @generated from field: analytics.v2.RawEventsRequest request = 1;
   */
  request?: RawEventsRequest

  constructor(data?: PartialMessage<DownloadRawEventsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DownloadRawEventsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'request', kind: 'message', T: RawEventsRequest }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DownloadRawEventsRequest {
    return new DownloadRawEventsRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DownloadRawEventsRequest {
    return new DownloadRawEventsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DownloadRawEventsRequest {
    return new DownloadRawEventsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: DownloadRawEventsRequest | PlainMessage<DownloadRawEventsRequest> | undefined,
    b: DownloadRawEventsRequest | PlainMessage<DownloadRawEventsRequest> | undefined
  ): boolean {
    return proto3.util.equals(DownloadRawEventsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.DownloadRawEventsResponse
 */
export class DownloadRawEventsResponse extends Message<DownloadRawEventsResponse> {
  /**
   * @generated from field: bytes data = 1;
   */
  data = new Uint8Array(0)

  constructor(data?: PartialMessage<DownloadRawEventsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DownloadRawEventsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'scalar', T: 12 /* ScalarType.BYTES */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DownloadRawEventsResponse {
    return new DownloadRawEventsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): DownloadRawEventsResponse {
    return new DownloadRawEventsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DownloadRawEventsResponse {
    return new DownloadRawEventsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: DownloadRawEventsResponse | PlainMessage<DownloadRawEventsResponse> | undefined,
    b: DownloadRawEventsResponse | PlainMessage<DownloadRawEventsResponse> | undefined
  ): boolean {
    return proto3.util.equals(DownloadRawEventsResponse, a, b)
  }
}

/**
 * Geo
 *
 * @generated from message analytics.v2.GeoAdminLevelRequest
 */
export class GeoAdminLevelRequest extends Message<GeoAdminLevelRequest> {
  /**
   * @generated from field: analytics.v2.Product product = 1;
   */
  product = Product.UNSPECIFIED

  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 2;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp

  /**
   * @generated from field: repeated analytics.v2.Coordinate viewport = 5;
   */
  viewport: Coordinate[] = []

  /**
   * @generated from field: analytics.v2.GeoAdministrationLevel geo_administration_level = 6;
   */
  geoAdministrationLevel = GeoAdministrationLevel.UNSPECIFIED

  /**
   * @generated from field: bool all_assets = 7;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 8;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 9;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 10;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 11;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 12;
   */
  tagName: string[] = []

  /**
   * @generated from field: bool geo_low_res = 13 [deprecated = true];
   * @deprecated
   */
  geoLowRes = false

  constructor(data?: PartialMessage<GeoAdminLevelRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.GeoAdminLevelRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    { no: 2, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 3, name: 'start_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'end_date', kind: 'message', T: Timestamp },
    { no: 5, name: 'viewport', kind: 'message', T: Coordinate, repeated: true },
    {
      no: 6,
      name: 'geo_administration_level',
      kind: 'enum',
      T: proto3.getEnumType(GeoAdministrationLevel)
    },
    { no: 7, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 12, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: 'geo_low_res', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoAdminLevelRequest {
    return new GeoAdminLevelRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoAdminLevelRequest {
    return new GeoAdminLevelRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GeoAdminLevelRequest {
    return new GeoAdminLevelRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GeoAdminLevelRequest | PlainMessage<GeoAdminLevelRequest> | undefined,
    b: GeoAdminLevelRequest | PlainMessage<GeoAdminLevelRequest> | undefined
  ): boolean {
    return proto3.util.equals(GeoAdminLevelRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.GeoAdminLevelResponse
 */
export class GeoAdminLevelResponse extends Message<GeoAdminLevelResponse> {
  /**
   * @generated from field: int32 maximum_count = 1;
   */
  maximumCount = 0

  /**
   * @generated from field: int32 interstitial_count = 2;
   */
  interstitialCount = 0

  /**
   * @generated from field: int32 ip_to_geo_count = 3;
   */
  ipToGeoCount = 0

  /**
   * @generated from field: analytics.v2.GeoJson geo_json = 4;
   */
  geoJson?: GeoJson

  constructor(data?: PartialMessage<GeoAdminLevelResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.GeoAdminLevelResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'maximum_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'interstitial_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'ip_to_geo_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'geo_json', kind: 'message', T: GeoJson }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GeoAdminLevelResponse {
    return new GeoAdminLevelResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoAdminLevelResponse {
    return new GeoAdminLevelResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GeoAdminLevelResponse {
    return new GeoAdminLevelResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GeoAdminLevelResponse | PlainMessage<GeoAdminLevelResponse> | undefined,
    b: GeoAdminLevelResponse | PlainMessage<GeoAdminLevelResponse> | undefined
  ): boolean {
    return proto3.util.equals(GeoAdminLevelResponse, a, b)
  }
}

/**
 * @generated from message analytics.v2.GeoScatterPlotRequest
 */
export class GeoScatterPlotRequest extends Message<GeoScatterPlotRequest> {
  /**
   * @generated from field: analytics.v2.Product product = 1;
   */
  product = Product.UNSPECIFIED

  /**
   * @generated from field: repeated analytics.v2.EventType event_type = 2;
   */
  eventType: EventType[] = []

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp

  /**
   * @generated from field: repeated analytics.v2.Coordinate viewport = 5;
   */
  viewport: Coordinate[] = []

  /**
   * @generated from field: analytics.v2.GeoAdministrationLevel geo_administration_level = 6;
   */
  geoAdministrationLevel = GeoAdministrationLevel.UNSPECIFIED

  /**
   * @generated from field: bool all_assets = 7;
   */
  allAssets = false

  /**
   * @generated from field: repeated string team_id = 8;
   */
  teamId: string[] = []

  /**
   * @generated from field: repeated string asset_id = 9;
   */
  assetId: string[] = []

  /**
   * @generated from field: repeated string page_id = 10;
   */
  pageId: string[] = []

  /**
   * @generated from field: repeated int32 directory_id = 11;
   */
  directoryId: number[] = []

  /**
   * @generated from field: repeated string tag_name = 12;
   */
  tagName: string[] = []

  /**
   * @generated from field: bool geo_low_res = 13 [deprecated = true];
   * @deprecated
   */
  geoLowRes = false

  constructor(data?: PartialMessage<GeoScatterPlotRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.GeoScatterPlotRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'product', kind: 'enum', T: proto3.getEnumType(Product) },
    { no: 2, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType), repeated: true },
    { no: 3, name: 'start_date', kind: 'message', T: Timestamp },
    { no: 4, name: 'end_date', kind: 'message', T: Timestamp },
    { no: 5, name: 'viewport', kind: 'message', T: Coordinate, repeated: true },
    {
      no: 6,
      name: 'geo_administration_level',
      kind: 'enum',
      T: proto3.getEnumType(GeoAdministrationLevel)
    },
    { no: 7, name: 'all_assets', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: 'team_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: 'directory_id', kind: 'scalar', T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 12, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: 'geo_low_res', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GeoScatterPlotRequest {
    return new GeoScatterPlotRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoScatterPlotRequest {
    return new GeoScatterPlotRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GeoScatterPlotRequest {
    return new GeoScatterPlotRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: GeoScatterPlotRequest | PlainMessage<GeoScatterPlotRequest> | undefined,
    b: GeoScatterPlotRequest | PlainMessage<GeoScatterPlotRequest> | undefined
  ): boolean {
    return proto3.util.equals(GeoScatterPlotRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.GeoScatterPlotResponse
 */
export class GeoScatterPlotResponse extends Message<GeoScatterPlotResponse> {
  /**
   * @generated from field: analytics.v2.GeoJson geo_json = 1;
   */
  geoJson?: GeoJson

  /**
   * @generated from field: repeated analytics.v2.ScatterPlotCoordinate plot_points = 2;
   */
  plotPoints: ScatterPlotCoordinate[] = []

  constructor(data?: PartialMessage<GeoScatterPlotResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.GeoScatterPlotResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'geo_json', kind: 'message', T: GeoJson },
    { no: 2, name: 'plot_points', kind: 'message', T: ScatterPlotCoordinate, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): GeoScatterPlotResponse {
    return new GeoScatterPlotResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): GeoScatterPlotResponse {
    return new GeoScatterPlotResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): GeoScatterPlotResponse {
    return new GeoScatterPlotResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: GeoScatterPlotResponse | PlainMessage<GeoScatterPlotResponse> | undefined,
    b: GeoScatterPlotResponse | PlainMessage<GeoScatterPlotResponse> | undefined
  ): boolean {
    return proto3.util.equals(GeoScatterPlotResponse, a, b)
  }
}

/**
 * @generated from message analytics.v2.OrgActivityRequest
 */
export class OrgActivityRequest extends Message<OrgActivityRequest> {
  constructor(data?: PartialMessage<OrgActivityRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.OrgActivityRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgActivityRequest {
    return new OrgActivityRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgActivityRequest {
    return new OrgActivityRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): OrgActivityRequest {
    return new OrgActivityRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: OrgActivityRequest | PlainMessage<OrgActivityRequest> | undefined,
    b: OrgActivityRequest | PlainMessage<OrgActivityRequest> | undefined
  ): boolean {
    return proto3.util.equals(OrgActivityRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.OrgActivityResponse
 */
export class OrgActivityResponse extends Message<OrgActivityResponse> {
  /**
   * @generated from field: analytics.v2.OrgActivityData data = 1;
   */
  data?: OrgActivityData

  constructor(data?: PartialMessage<OrgActivityResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.OrgActivityResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: OrgActivityData }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgActivityResponse {
    return new OrgActivityResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgActivityResponse {
    return new OrgActivityResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): OrgActivityResponse {
    return new OrgActivityResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: OrgActivityResponse | PlainMessage<OrgActivityResponse> | undefined,
    b: OrgActivityResponse | PlainMessage<OrgActivityResponse> | undefined
  ): boolean {
    return proto3.util.equals(OrgActivityResponse, a, b)
  }
}

/**
 * @generated from message analytics.v2.ListDataCappedOrgsRequest
 */
export class ListDataCappedOrgsRequest extends Message<ListDataCappedOrgsRequest> {
  constructor(data?: PartialMessage<ListDataCappedOrgsRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.ListDataCappedOrgsRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListDataCappedOrgsRequest {
    return new ListDataCappedOrgsRequest().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListDataCappedOrgsRequest {
    return new ListDataCappedOrgsRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListDataCappedOrgsRequest {
    return new ListDataCappedOrgsRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListDataCappedOrgsRequest | PlainMessage<ListDataCappedOrgsRequest> | undefined,
    b: ListDataCappedOrgsRequest | PlainMessage<ListDataCappedOrgsRequest> | undefined
  ): boolean {
    return proto3.util.equals(ListDataCappedOrgsRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.ListDataCappedOrgsResponse
 */
export class ListDataCappedOrgsResponse extends Message<ListDataCappedOrgsResponse> {
  /**
   * @generated from field: repeated analytics.v2.OrgActivityData data = 1;
   */
  data: OrgActivityData[] = []

  constructor(data?: PartialMessage<ListDataCappedOrgsResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.ListDataCappedOrgsResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data', kind: 'message', T: OrgActivityData, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ListDataCappedOrgsResponse {
    return new ListDataCappedOrgsResponse().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): ListDataCappedOrgsResponse {
    return new ListDataCappedOrgsResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ListDataCappedOrgsResponse {
    return new ListDataCappedOrgsResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: ListDataCappedOrgsResponse | PlainMessage<ListDataCappedOrgsResponse> | undefined,
    b: ListDataCappedOrgsResponse | PlainMessage<ListDataCappedOrgsResponse> | undefined
  ): boolean {
    return proto3.util.equals(ListDataCappedOrgsResponse, a, b)
  }
}

/**
 * @generated from message analytics.v2.PurgeOrgCacheRequest
 */
export class PurgeOrgCacheRequest extends Message<PurgeOrgCacheRequest> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  constructor(data?: PartialMessage<PurgeOrgCacheRequest>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.PurgeOrgCacheRequest'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PurgeOrgCacheRequest {
    return new PurgeOrgCacheRequest().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PurgeOrgCacheRequest {
    return new PurgeOrgCacheRequest().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): PurgeOrgCacheRequest {
    return new PurgeOrgCacheRequest().fromJsonString(jsonString, options)
  }

  static equals(
    a: PurgeOrgCacheRequest | PlainMessage<PurgeOrgCacheRequest> | undefined,
    b: PurgeOrgCacheRequest | PlainMessage<PurgeOrgCacheRequest> | undefined
  ): boolean {
    return proto3.util.equals(PurgeOrgCacheRequest, a, b)
  }
}

/**
 * @generated from message analytics.v2.PurgeOrgCacheResponse
 */
export class PurgeOrgCacheResponse extends Message<PurgeOrgCacheResponse> {
  constructor(data?: PartialMessage<PurgeOrgCacheResponse>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.PurgeOrgCacheResponse'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): PurgeOrgCacheResponse {
    return new PurgeOrgCacheResponse().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PurgeOrgCacheResponse {
    return new PurgeOrgCacheResponse().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): PurgeOrgCacheResponse {
    return new PurgeOrgCacheResponse().fromJsonString(jsonString, options)
  }

  static equals(
    a: PurgeOrgCacheResponse | PlainMessage<PurgeOrgCacheResponse> | undefined,
    b: PurgeOrgCacheResponse | PlainMessage<PurgeOrgCacheResponse> | undefined
  ): boolean {
    return proto3.util.equals(PurgeOrgCacheResponse, a, b)
  }
}
