import { AnalyticsService } from '@dtx-company/connect-codegen/src/gen/analytics/v2/service_connect'
import { Asset } from '@dtx-company/connect-codegen/src/gen/asset/v1/asset_pb'
import { Code } from '@dtx-company/connect-codegen/src/gen/codes/v2/code_pb'
import { PromiseClient, createPromiseClient } from '@bufbuild/connect'
import { Page as V1Page } from '@dtx-company/connect-codegen/src/gen/pages/v1/page_pb'
import { Page as V2Page } from '@dtx-company/connect-codegen/src/gen/pages/v2/page_pb'
import { createConnectTransport } from '@bufbuild/connect-web'
import { createRegistry } from '@bufbuild/protobuf'
import { getBaseUrl } from '../utils/url'

export const assetTypeRegistry = createRegistry(Code, V1Page, V2Page, Asset)

export const getAnalyticsServiceBaseUrl = (): string =>
  getBaseUrl('analytics/internal/', process.env.APP_ENV)

const analyticsClient: PromiseClient<typeof AnalyticsService> = createPromiseClient(
  AnalyticsService,
  createConnectTransport({
    baseUrl: getAnalyticsServiceBaseUrl(),
    jsonOptions: {
      ignoreUnknownFields: true
    }
  })
)

export default analyticsClient
