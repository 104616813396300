// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file codes/v2/code.proto (package codes.v2, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, Struct } from '@bufbuild/protobuf'

/**
 * @generated from enum codes.v2.DestinationType
 */
export enum DestinationType {
  /**
   * @generated from enum value: DESTINATION_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DESTINATION_TYPE_URL = 1;
   */
  URL = 1,

  /**
   * @generated from enum value: DESTINATION_TYPE_SMS = 2;
   */
  SMS = 2,

  /**
   * @generated from enum value: DESTINATION_TYPE_EVENT = 3;
   */
  EVENT = 3,

  /**
   * @generated from enum value: DESTINATION_TYPE_FILE = 4;
   */
  FILE = 4,

  /**
   * @generated from enum value: DESTINATION_TYPE_VENMO = 5;
   */
  VENMO = 5,

  /**
   * @generated from enum value: DESTINATION_TYPE_INSTAGRAM = 6;
   */
  INSTAGRAM = 6,

  /**
   * @generated from enum value: DESTINATION_TYPE_FACEBOOK = 7;
   */
  FACEBOOK = 7,

  /**
   * @generated from enum value: DESTINATION_TYPE_LINKEDIN = 8;
   */
  LINKEDIN = 8,

  /**
   * @generated from enum value: DESTINATION_TYPE_TIKTOK = 9;
   */
  TIKTOK = 9,

  /**
   * @generated from enum value: DESTINATION_TYPE_SNAPCHAT = 10;
   */
  SNAPCHAT = 10,

  /**
   * @generated from enum value: DESTINATION_TYPE_TWITTER = 11;
   */
  TWITTER = 11,

  /**
   * @generated from enum value: DESTINATION_TYPE_SPOTIFY = 12;
   */
  SPOTIFY = 12,

  /**
   * @generated from enum value: DESTINATION_TYPE_GOOGLE_DOC = 13;
   */
  GOOGLE_DOC = 13,

  /**
   * @generated from enum value: DESTINATION_TYPE_YOUTUBE = 14;
   */
  YOUTUBE = 14,

  /**
   * @generated from enum value: DESTINATION_TYPE_SOCIAL = 15;
   */
  SOCIAL = 15,

  /**
   * @generated from enum value: DESTINATION_TYPE_PHONE = 16;
   */
  PHONE = 16,

  /**
   * @generated from enum value: DESTINATION_TYPE_FLOWPAGE = 17;
   */
  FLOWPAGE = 17,

  /**
   * @generated from enum value: DESTINATION_TYPE_WIFI = 18;
   */
  WIFI = 18,

  /**
   * @generated from enum value: DESTINATION_TYPE_SOCIAL_MEDIA = 19;
   */
  SOCIAL_MEDIA = 19,

  /**
   * @generated from enum value: DESTINATION_TYPE_SCAN_TO_OWN = 20;
   */
  SCAN_TO_OWN = 20,

  /**
   * @generated from enum value: DESTINATION_TYPE_SCAN_TO_ACTIVATE = 21;
   */
  SCAN_TO_ACTIVATE = 21,

  /**
   * @generated from enum value: DESTINATION_TYPE_PRESET_FP = 22;
   */
  PRESET_FP = 22,

  /**
   * @generated from enum value: DESTINATION_TYPE_COLLECTIBLE_FP = 23;
   */
  COLLECTIBLE_FP = 23,

  /**
   * @generated from enum value: DESTINATION_TYPE_ONE_TIME_URL = 24;
   */
  ONE_TIME_URL = 24,

  /**
   * @generated from enum value: DESTINATION_TYPE_VCARD = 25;
   */
  VCARD = 25,

  /**
   * @generated from enum value: DESTINATION_TYPE_RULE = 26;
   */
  RULE = 26,

  /**
   * @generated from enum value: DESTINATION_TYPE_EMAIL = 27;
   */
  EMAIL = 27
}
// Retrieve enum metadata with: proto3.getEnumType(DestinationType)
proto3.util.setEnumType(DestinationType, 'codes.v2.DestinationType', [
  { no: 0, name: 'DESTINATION_TYPE_UNSPECIFIED' },
  { no: 1, name: 'DESTINATION_TYPE_URL' },
  { no: 2, name: 'DESTINATION_TYPE_SMS' },
  { no: 3, name: 'DESTINATION_TYPE_EVENT' },
  { no: 4, name: 'DESTINATION_TYPE_FILE' },
  { no: 5, name: 'DESTINATION_TYPE_VENMO' },
  { no: 6, name: 'DESTINATION_TYPE_INSTAGRAM' },
  { no: 7, name: 'DESTINATION_TYPE_FACEBOOK' },
  { no: 8, name: 'DESTINATION_TYPE_LINKEDIN' },
  { no: 9, name: 'DESTINATION_TYPE_TIKTOK' },
  { no: 10, name: 'DESTINATION_TYPE_SNAPCHAT' },
  { no: 11, name: 'DESTINATION_TYPE_TWITTER' },
  { no: 12, name: 'DESTINATION_TYPE_SPOTIFY' },
  { no: 13, name: 'DESTINATION_TYPE_GOOGLE_DOC' },
  { no: 14, name: 'DESTINATION_TYPE_YOUTUBE' },
  { no: 15, name: 'DESTINATION_TYPE_SOCIAL' },
  { no: 16, name: 'DESTINATION_TYPE_PHONE' },
  { no: 17, name: 'DESTINATION_TYPE_FLOWPAGE' },
  { no: 18, name: 'DESTINATION_TYPE_WIFI' },
  { no: 19, name: 'DESTINATION_TYPE_SOCIAL_MEDIA' },
  { no: 20, name: 'DESTINATION_TYPE_SCAN_TO_OWN' },
  { no: 21, name: 'DESTINATION_TYPE_SCAN_TO_ACTIVATE' },
  { no: 22, name: 'DESTINATION_TYPE_PRESET_FP' },
  { no: 23, name: 'DESTINATION_TYPE_COLLECTIBLE_FP' },
  { no: 24, name: 'DESTINATION_TYPE_ONE_TIME_URL' },
  { no: 25, name: 'DESTINATION_TYPE_VCARD' },
  { no: 26, name: 'DESTINATION_TYPE_RULE' },
  { no: 27, name: 'DESTINATION_TYPE_EMAIL' }
])

/**
 * Deprecated in favor of a sticky/internal/persistent tag concept
 *
 * @generated from enum codes.v2.CreationSource
 */
export enum CreationSource {
  /**
   * @generated from enum value: CREATION_SOURCE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CREATION_SOURCE_FLOWTAG = 1;
   */
  FLOWTAG = 1,

  /**
   * @generated from enum value: CREATION_SOURCE_STARTER_KIT = 2;
   */
  STARTER_KIT = 2,

  /**
   * @generated from enum value: CREATION_SOURCE_VIDEO_TOOL = 3;
   */
  VIDEO_TOOL = 3
}
// Retrieve enum metadata with: proto3.getEnumType(CreationSource)
proto3.util.setEnumType(CreationSource, 'codes.v2.CreationSource', [
  { no: 0, name: 'CREATION_SOURCE_UNSPECIFIED' },
  { no: 1, name: 'CREATION_SOURCE_FLOWTAG' },
  { no: 2, name: 'CREATION_SOURCE_STARTER_KIT' },
  { no: 3, name: 'CREATION_SOURCE_VIDEO_TOOL' }
])

/**
 * @generated from message codes.v2.CodeDesign
 */
export class CodeDesign extends Message<CodeDesign> {
  /**
   * id represents the the studio_config_id
   *
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * The configuration blob that holds the config required to render a flowcode
   *
   * @generated from field: google.protobuf.Struct config = 2;
   */
  config?: Struct

  constructor(data?: PartialMessage<CodeDesign>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'codes.v2.CodeDesign'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'config', kind: 'message', T: Struct }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CodeDesign {
    return new CodeDesign().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CodeDesign {
    return new CodeDesign().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CodeDesign {
    return new CodeDesign().fromJsonString(jsonString, options)
  }

  static equals(
    a: CodeDesign | PlainMessage<CodeDesign> | undefined,
    b: CodeDesign | PlainMessage<CodeDesign> | undefined
  ): boolean {
    return proto3.util.equals(CodeDesign, a, b)
  }
}

/**
 * @generated from message codes.v2.Destination
 */
export class Destination extends Message<Destination> {
  /**
   * Value is the redirect_value of the code
   *
   * @generated from field: string value = 1;
   */
  value = ''

  /**
   * Rule is an optional redirect rule attached to a code
   *
   * @generated from field: optional google.protobuf.Struct rule = 2;
   */
  rule?: Struct

  /**
   * Type describes the "type" of destination a code leads to
   *
   * @generated from field: codes.v2.DestinationType type = 3;
   */
  type = DestinationType.UNSPECIFIED

  /**
   * IsActive denotes whether the destination is active or not in the case
   * where multiple destinations are stored per code
   *
   * @generated from field: bool is_active = 5;
   */
  isActive = false

  constructor(data?: PartialMessage<Destination>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'codes.v2.Destination'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'value', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'rule', kind: 'message', T: Struct, opt: true },
    { no: 3, name: 'type', kind: 'enum', T: proto3.getEnumType(DestinationType) },
    { no: 5, name: 'is_active', kind: 'scalar', T: 8 /* ScalarType.BOOL */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Destination {
    return new Destination().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Destination {
    return new Destination().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Destination {
    return new Destination().fromJsonString(jsonString, options)
  }

  static equals(
    a: Destination | PlainMessage<Destination> | undefined,
    b: Destination | PlainMessage<Destination> | undefined
  ): boolean {
    return proto3.util.equals(Destination, a, b)
  }
}

/**
 * Code is the reprensation of a flowcode containing:
 *   - the data it needs to render an image of it
 *   - the scan destination which determines the interaction a user has when scanning
 *
 * @generated from message codes.v2.Code
 */
export class Code extends Message<Code> {
  /**
   * Batch Id represents which batch the code is a part of. The batch is controls the different elements of a code. It's destination, the way it looks in the world, etc.
   * The actual asset.id of a code is synonymous with the Redirect Id
   *
   * @generated from field: string batch_id = 1;
   */
  batchId = ''

  /**
   * IsStatic is used to determine if the code is encoded with the short_url or just the value
   *
   * This is primarly used for wifi codes which don't support redirects since the camera needs to read
   * the value directly
   *
   * Any code can be static. Static codes do not collect scan data
   *
   * @generated from field: bool is_static = 2;
   */
  isStatic = false

  /**
   * This is the url used to encode the flowcode.
   *
   * Shorturl uses a flowcode domain or in the case of white-label redirects
   * it will contain a different domain
   *
   * @generated from field: string short_url = 3;
   */
  shortUrl = ''

  /**
   * Destination describes what happens when a user scans the code
   * Codes can contain multiple active and inactive destinations depending on which is toggle on
   *
   * @generated from field: repeated codes.v2.Destination destination = 4;
   */
  destination: Destination[] = []

  /**
   * CodeDesign describes what the code looks like
   * The config is fed into the flowcode-generator-v2 which is responsible for interpretting the values
   *
   * @generated from field: codes.v2.CodeDesign code_design = 5;
   */
  codeDesign?: CodeDesign

  /**
   * Deprecated in favor of sticky/internal/persistent tags
   *
   * @generated from field: codes.v2.CreationSource creation_source = 7;
   */
  creationSource = CreationSource.UNSPECIFIED

  constructor(data?: PartialMessage<Code>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'codes.v2.Code'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'batch_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'is_static', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: 'short_url', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'destination', kind: 'message', T: Destination, repeated: true },
    { no: 5, name: 'code_design', kind: 'message', T: CodeDesign },
    { no: 7, name: 'creation_source', kind: 'enum', T: proto3.getEnumType(CreationSource) }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Code {
    return new Code().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Code {
    return new Code().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Code {
    return new Code().fromJsonString(jsonString, options)
  }

  static equals(
    a: Code | PlainMessage<Code> | undefined,
    b: Code | PlainMessage<Code> | undefined
  ): boolean {
    return proto3.util.equals(Code, a, b)
  }
}
