// @generated by protoc-gen-connect-es v0.8.6 with parameter "target=ts,import_extension="
// @generated from file analytics/v2/service.proto (package analytics.v2, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import {
  CompetitiveBenchmarkingRequest,
  CompetitiveBenchmarkingResponse,
  CountRequest,
  CountResponse,
  DateRangePixelsRequest,
  DateRangePixelsResponse,
  DateRangeRequest,
  DateRangeResponse,
  DownloadRawEventsRequest,
  DownloadRawEventsResponse,
  DownloadRequest,
  DownloadResponse,
  GeoAdminLevelRequest,
  GeoAdminLevelResponse,
  GeoScatterPlotRequest,
  GeoScatterPlotResponse,
  HealthCheckRequest,
  HealthCheckResponse,
  ListDataCappedOrgsRequest,
  ListDataCappedOrgsResponse,
  OrgActivityRequest,
  OrgActivityResponse,
  PurgeOrgCacheRequest,
  PurgeOrgCacheResponse,
  RangeSummaryRequest,
  RangeSummaryResponse,
  RawEventsRequest,
  RawEventsResponse,
  SummaryPixelsRequest,
  SummaryPixelsResponse,
  SummaryRequest,
  SummaryResponse,
  TopCitiesRequest,
  TopCitiesResponse,
  TopCodesPixelsRequest,
  TopCodesPixelsResponse,
  TopCodesRequest,
  TopCodesResponse,
  TopDaysRequest,
  TopDaysResponse,
  TopDestinationsRequest,
  TopDestinationsResponse,
  TopDevicesRequest,
  TopDevicesResponse,
  TopHoursRequest,
  TopHoursResponse,
  TopLinksRequest,
  TopLinksResponse,
  TopOperatingSystemsRequest,
  TopOperatingSystemsResponse,
  TopPagesRequest,
  TopPagesResponse,
  TopPathsPixelsRequest,
  TopPathsPixelsResponse,
  TopStatesRequest,
  TopStatesResponse,
  TopTagsRequest,
  TopTagsResponse,
  TopUTMCampaignsRequest,
  TopUTMCampaignsResponse,
  TopUTMReferrersRequest,
  TopUTMReferrersResponse,
  TopUTMTermsRequest,
  TopUTMTermsResponse
} from './service_pb'
import { MethodKind } from '@bufbuild/protobuf'

/**
 * Service
 *
 * @generated from service analytics.v2.AnalyticsService
 */
export const AnalyticsService = {
  typeName: 'analytics.v2.AnalyticsService',
  methods: {
    /**
     * @generated from rpc analytics.v2.AnalyticsService.HealthCheck
     */
    healthCheck: {
      name: 'HealthCheck',
      I: HealthCheckRequest,
      O: HealthCheckResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.CompetitiveBenchmarking
     */
    competitiveBenchmarking: {
      name: 'CompetitiveBenchmarking',
      I: CompetitiveBenchmarkingRequest,
      O: CompetitiveBenchmarkingResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.RangeSummary
     */
    rangeSummary: {
      name: 'RangeSummary',
      I: RangeSummaryRequest,
      O: RangeSummaryResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.Summary
     */
    summary: {
      name: 'Summary',
      I: SummaryRequest,
      O: SummaryResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.SummaryPixels
     */
    summaryPixels: {
      name: 'SummaryPixels',
      I: SummaryPixelsRequest,
      O: SummaryPixelsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.Count
     */
    count: {
      name: 'Count',
      I: CountRequest,
      O: CountResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.RawEvents
     */
    rawEvents: {
      name: 'RawEvents',
      I: RawEventsRequest,
      O: RawEventsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopCodes
     */
    topCodes: {
      name: 'TopCodes',
      I: TopCodesRequest,
      O: TopCodesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopCodesPixels
     */
    topCodesPixels: {
      name: 'TopCodesPixels',
      I: TopCodesPixelsRequest,
      O: TopCodesPixelsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopPathsPixels
     */
    topPathsPixels: {
      name: 'TopPathsPixels',
      I: TopPathsPixelsRequest,
      O: TopPathsPixelsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopDestinations
     */
    topDestinations: {
      name: 'TopDestinations',
      I: TopDestinationsRequest,
      O: TopDestinationsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopPages
     */
    topPages: {
      name: 'TopPages',
      I: TopPagesRequest,
      O: TopPagesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopLinks
     */
    topLinks: {
      name: 'TopLinks',
      I: TopLinksRequest,
      O: TopLinksResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopUTMCampaigns
     */
    topUTMCampaigns: {
      name: 'TopUTMCampaigns',
      I: TopUTMCampaignsRequest,
      O: TopUTMCampaignsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopUTMTerms
     */
    topUTMTerms: {
      name: 'TopUTMTerms',
      I: TopUTMTermsRequest,
      O: TopUTMTermsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopUTMReferrers
     */
    topUTMReferrers: {
      name: 'TopUTMReferrers',
      I: TopUTMReferrersRequest,
      O: TopUTMReferrersResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopCities
     */
    topCities: {
      name: 'TopCities',
      I: TopCitiesRequest,
      O: TopCitiesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopStates
     */
    topStates: {
      name: 'TopStates',
      I: TopStatesRequest,
      O: TopStatesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopDays
     */
    topDays: {
      name: 'TopDays',
      I: TopDaysRequest,
      O: TopDaysResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopHours
     */
    topHours: {
      name: 'TopHours',
      I: TopHoursRequest,
      O: TopHoursResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopDevices
     */
    topDevices: {
      name: 'TopDevices',
      I: TopDevicesRequest,
      O: TopDevicesResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopOperatingSystems
     */
    topOperatingSystems: {
      name: 'TopOperatingSystems',
      I: TopOperatingSystemsRequest,
      O: TopOperatingSystemsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.TopTags
     */
    topTags: {
      name: 'TopTags',
      I: TopTagsRequest,
      O: TopTagsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.DateRange
     */
    dateRange: {
      name: 'DateRange',
      I: DateRangeRequest,
      O: DateRangeResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.DateRangePixels
     */
    dateRangePixels: {
      name: 'DateRangePixels',
      I: DateRangePixelsRequest,
      O: DateRangePixelsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.Download
     */
    download: {
      name: 'Download',
      I: DownloadRequest,
      O: DownloadResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.DownloadRawEvents
     */
    downloadRawEvents: {
      name: 'DownloadRawEvents',
      I: DownloadRawEventsRequest,
      O: DownloadRawEventsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.GeoAdminLevel
     */
    geoAdminLevel: {
      name: 'GeoAdminLevel',
      I: GeoAdminLevelRequest,
      O: GeoAdminLevelResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.GeoScatterPlot
     */
    geoScatterPlot: {
      name: 'GeoScatterPlot',
      I: GeoScatterPlotRequest,
      O: GeoScatterPlotResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.OrgActivity
     */
    orgActivity: {
      name: 'OrgActivity',
      I: OrgActivityRequest,
      O: OrgActivityResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.ListDataCappedOrgs
     */
    listDataCappedOrgs: {
      name: 'ListDataCappedOrgs',
      I: ListDataCappedOrgsRequest,
      O: ListDataCappedOrgsResponse,
      kind: MethodKind.Unary
    },
    /**
     * @generated from rpc analytics.v2.AnalyticsService.PurgeOrgCache
     */
    purgeOrgCache: {
      name: 'PurgeOrgCache',
      I: PurgeOrgCacheRequest,
      O: PurgeOrgCacheResponse,
      kind: MethodKind.Unary
    }
  }
} as const
