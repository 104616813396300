// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file analytics/v2/analytics.proto (package analytics.v2, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, protoInt64, Timestamp } from '@bufbuild/protobuf'

/**
 * Types
 *
 * @generated from enum analytics.v2.Product
 */
export enum Product {
  /**
   * @generated from enum value: PRODUCT_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: PRODUCT_FLOWPAGE = 1;
   */
  FLOWPAGE = 1,

  /**
   * @generated from enum value: PRODUCT_ALL = 2;
   */
  ALL = 2,

  /**
   * @generated from enum value: PRODUCT_FLOWCODE = 3;
   */
  FLOWCODE = 3,

  /**
   * @generated from enum value: PRODUCT_PIXEL = 4;
   */
  PIXEL = 4
}
// Retrieve enum metadata with: proto3.getEnumType(Product)
proto3.util.setEnumType(Product, 'analytics.v2.Product', [
  { no: 0, name: 'PRODUCT_UNSPECIFIED' },
  { no: 1, name: 'PRODUCT_FLOWPAGE' },
  { no: 2, name: 'PRODUCT_ALL' },
  { no: 3, name: 'PRODUCT_FLOWCODE' },
  { no: 4, name: 'PRODUCT_PIXEL' }
])

/**
 * @generated from enum analytics.v2.EventType
 */
export enum EventType {
  /**
   * @generated from enum value: EVENT_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: EVENT_TYPE_SCAN = 1;
   */
  SCAN = 1,

  /**
   * @generated from enum value: EVENT_TYPE_SHORT_URL_CLICK = 2;
   */
  SHORT_URL_CLICK = 2,

  /**
   * @generated from enum value: EVENT_TYPE_TAP = 3;
   */
  TAP = 3,

  /**
   * @generated from enum value: EVENT_TYPE_PAGE_VIEW = 4;
   */
  PAGE_VIEW = 4,

  /**
   * @generated from enum value: EVENT_TYPE_LINK_CLICK = 5;
   */
  LINK_CLICK = 5,

  /**
   * @generated from enum value: EVENT_TYPE_CRM = 6;
   */
  CRM = 6,

  /**
   * @generated from enum value: EVENT_TYPE_PIXEL_PAGE_VIEW = 7;
   */
  PIXEL_PAGE_VIEW = 7,

  /**
   * @generated from enum value: EVENT_TYPE_PIXEL_CONVERSION = 8;
   */
  PIXEL_CONVERSION = 8,

  /**
   * @generated from enum value: EVENT_TYPE_PIXEL_BUTTON_CLICK = 9;
   */
  PIXEL_BUTTON_CLICK = 9,

  /**
   * @generated from enum value: EVENT_TYPE_PIXEL_LINK_CLICK = 10;
   */
  PIXEL_LINK_CLICK = 10
}
// Retrieve enum metadata with: proto3.getEnumType(EventType)
proto3.util.setEnumType(EventType, 'analytics.v2.EventType', [
  { no: 0, name: 'EVENT_TYPE_UNSPECIFIED' },
  { no: 1, name: 'EVENT_TYPE_SCAN' },
  { no: 2, name: 'EVENT_TYPE_SHORT_URL_CLICK' },
  { no: 3, name: 'EVENT_TYPE_TAP' },
  { no: 4, name: 'EVENT_TYPE_PAGE_VIEW' },
  { no: 5, name: 'EVENT_TYPE_LINK_CLICK' },
  { no: 6, name: 'EVENT_TYPE_CRM' },
  { no: 7, name: 'EVENT_TYPE_PIXEL_PAGE_VIEW' },
  { no: 8, name: 'EVENT_TYPE_PIXEL_CONVERSION' },
  { no: 9, name: 'EVENT_TYPE_PIXEL_BUTTON_CLICK' },
  { no: 10, name: 'EVENT_TYPE_PIXEL_LINK_CLICK' }
])

/**
 * @generated from enum analytics.v2.Interval
 */
export enum Interval {
  /**
   * @generated from enum value: INTERVAL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: INTERVAL_HOURLY = 1;
   */
  HOURLY = 1,

  /**
   * @generated from enum value: INTERVAL_DAILY = 2;
   */
  DAILY = 2,

  /**
   * @generated from enum value: INTERVAL_WEEKLY = 3;
   */
  WEEKLY = 3
}
// Retrieve enum metadata with: proto3.getEnumType(Interval)
proto3.util.setEnumType(Interval, 'analytics.v2.Interval', [
  { no: 0, name: 'INTERVAL_UNSPECIFIED' },
  { no: 1, name: 'INTERVAL_HOURLY' },
  { no: 2, name: 'INTERVAL_DAILY' },
  { no: 3, name: 'INTERVAL_WEEKLY' }
])

/**
 * @generated from enum analytics.v2.Device
 */
export enum Device {
  /**
   * @generated from enum value: DEVICE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DEVICE_MOBILE = 1;
   */
  MOBILE = 1,

  /**
   * @generated from enum value: DEVICE_TABLET = 2;
   */
  TABLET = 2,

  /**
   * @generated from enum value: DEVICE_DESKTOP = 3;
   */
  DESKTOP = 3,

  /**
   * @generated from enum value: DEVICE_UNKNOWN = 4;
   */
  UNKNOWN = 4
}
// Retrieve enum metadata with: proto3.getEnumType(Device)
proto3.util.setEnumType(Device, 'analytics.v2.Device', [
  { no: 0, name: 'DEVICE_UNSPECIFIED' },
  { no: 1, name: 'DEVICE_MOBILE' },
  { no: 2, name: 'DEVICE_TABLET' },
  { no: 3, name: 'DEVICE_DESKTOP' },
  { no: 4, name: 'DEVICE_UNKNOWN' }
])

/**
 * @generated from enum analytics.v2.OperatingSystem
 */
export enum OperatingSystem {
  /**
   * @generated from enum value: OPERATING_SYSTEM_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: OPERATING_SYSTEM_ANDROID = 1;
   */
  ANDROID = 1,

  /**
   * @generated from enum value: OPERATING_SYSTEM_WINDOWS = 2;
   */
  WINDOWS = 2,

  /**
   * @generated from enum value: OPERATING_SYSTEM_IOS = 3;
   */
  IOS = 3,

  /**
   * @generated from enum value: OPERATING_SYSTEM_MACOS = 4;
   */
  MACOS = 4,

  /**
   * @generated from enum value: OPERATING_SYSTEM_LINUX = 5;
   */
  LINUX = 5,

  /**
   * @generated from enum value: OPERATING_SYSTEM_CHROMEOS = 6;
   */
  CHROMEOS = 6,

  /**
   * @generated from enum value: OPERATING_SYSTEM_UNKNOWN = 7;
   */
  UNKNOWN = 7
}
// Retrieve enum metadata with: proto3.getEnumType(OperatingSystem)
proto3.util.setEnumType(OperatingSystem, 'analytics.v2.OperatingSystem', [
  { no: 0, name: 'OPERATING_SYSTEM_UNSPECIFIED' },
  { no: 1, name: 'OPERATING_SYSTEM_ANDROID' },
  { no: 2, name: 'OPERATING_SYSTEM_WINDOWS' },
  { no: 3, name: 'OPERATING_SYSTEM_IOS' },
  { no: 4, name: 'OPERATING_SYSTEM_MACOS' },
  { no: 5, name: 'OPERATING_SYSTEM_LINUX' },
  { no: 6, name: 'OPERATING_SYSTEM_CHROMEOS' },
  { no: 7, name: 'OPERATING_SYSTEM_UNKNOWN' }
])

/**
 * @generated from enum analytics.v2.RawEventsCol
 */
export enum RawEventsCol {
  /**
   * @generated from enum value: COL_UNSPECIFIED = 0;
   */
  COL_UNSPECIFIED = 0,

  /**
   * @generated from enum value: COL_ID = 1;
   */
  COL_ID = 1,

  /**
   * @generated from enum value: COL_EVENT_TIME = 2;
   */
  COL_EVENT_TIME = 2,

  /**
   * @generated from enum value: COL_ASSET_ID = 3;
   */
  COL_ASSET_ID = 3,

  /**
   * @generated from enum value: COL_ASSET_NAME = 4;
   */
  COL_ASSET_NAME = 4,

  /**
   * @generated from enum value: COL_DIRECTORY_NAME = 5;
   */
  COL_DIRECTORY_NAME = 5,

  /**
   * @generated from enum value: COL_OPERATING_SYSTEM = 6;
   */
  COL_OPERATING_SYSTEM = 6,

  /**
   * @generated from enum value: COL_DEVICE = 7;
   */
  COL_DEVICE = 7,

  /**
   * @generated from enum value: COL_EVENT_TYPE = 8;
   */
  COL_EVENT_TYPE = 8,

  /**
   * @generated from enum value: COL_DESTINATION = 9;
   */
  COL_DESTINATION = 9,

  /**
   * @generated from enum value: COL_LINK_NAME = 10;
   */
  COL_LINK_NAME = 10,

  /**
   * @generated from enum value: COL_COUNTRY = 11;
   */
  COL_COUNTRY = 11,

  /**
   * @generated from enum value: COL_STATE = 12;
   */
  COL_STATE = 12,

  /**
   * @generated from enum value: COL_CITY = 13;
   */
  COL_CITY = 13,

  /**
   * @generated from enum value: COL_ZIP = 14;
   */
  COL_ZIP = 14,

  /**
   * @generated from enum value: COL_LATITUDE = 15;
   */
  COL_LATITUDE = 15,

  /**
   * @generated from enum value: COL_LONGITUDE = 16;
   */
  COL_LONGITUDE = 16,

  /**
   * @generated from enum value: COL_GEO_TYPE = 17;
   */
  COL_GEO_TYPE = 17,

  /**
   * @generated from enum value: COL_APP_ID = 18;
   */
  COL_APP_ID = 18
}
// Retrieve enum metadata with: proto3.getEnumType(RawEventsCol)
proto3.util.setEnumType(RawEventsCol, 'analytics.v2.RawEventsCol', [
  { no: 0, name: 'COL_UNSPECIFIED' },
  { no: 1, name: 'COL_ID' },
  { no: 2, name: 'COL_EVENT_TIME' },
  { no: 3, name: 'COL_ASSET_ID' },
  { no: 4, name: 'COL_ASSET_NAME' },
  { no: 5, name: 'COL_DIRECTORY_NAME' },
  { no: 6, name: 'COL_OPERATING_SYSTEM' },
  { no: 7, name: 'COL_DEVICE' },
  { no: 8, name: 'COL_EVENT_TYPE' },
  { no: 9, name: 'COL_DESTINATION' },
  { no: 10, name: 'COL_LINK_NAME' },
  { no: 11, name: 'COL_COUNTRY' },
  { no: 12, name: 'COL_STATE' },
  { no: 13, name: 'COL_CITY' },
  { no: 14, name: 'COL_ZIP' },
  { no: 15, name: 'COL_LATITUDE' },
  { no: 16, name: 'COL_LONGITUDE' },
  { no: 17, name: 'COL_GEO_TYPE' },
  { no: 18, name: 'COL_APP_ID' }
])

/**
 * @generated from enum analytics.v2.GeoAdministrationLevel
 */
export enum GeoAdministrationLevel {
  /**
   * @generated from enum value: GEO_ADMINISTRATION_LEVEL_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: GEO_ADMINISTRATION_LEVEL_COUNTRY = 1;
   */
  COUNTRY = 1,

  /**
   * @generated from enum value: GEO_ADMINISTRATION_LEVEL_REGION = 2;
   */
  REGION = 2,

  /**
   * @generated from enum value: GEO_ADMINISTRATION_LEVEL_STATE = 3;
   */
  STATE = 3,

  /**
   * @generated from enum value: GEO_ADMINISTRATION_LEVEL_COUNTY = 4;
   */
  COUNTY = 4,

  /**
   * @generated from enum value: GEO_ADMINISTRATION_LEVEL_POSTAL_CODE = 5;
   */
  POSTAL_CODE = 5
}
// Retrieve enum metadata with: proto3.getEnumType(GeoAdministrationLevel)
proto3.util.setEnumType(GeoAdministrationLevel, 'analytics.v2.GeoAdministrationLevel', [
  { no: 0, name: 'GEO_ADMINISTRATION_LEVEL_UNSPECIFIED' },
  { no: 1, name: 'GEO_ADMINISTRATION_LEVEL_COUNTRY' },
  { no: 2, name: 'GEO_ADMINISTRATION_LEVEL_REGION' },
  { no: 3, name: 'GEO_ADMINISTRATION_LEVEL_STATE' },
  { no: 4, name: 'GEO_ADMINISTRATION_LEVEL_COUNTY' },
  { no: 5, name: 'GEO_ADMINISTRATION_LEVEL_POSTAL_CODE' }
])

/**
 * Coordinates
 *
 * @generated from message analytics.v2.Coordinate
 */
export class Coordinate extends Message<Coordinate> {
  /**
   * @generated from field: float longitude = 1;
   */
  longitude = 0

  /**
   * @generated from field: float latitude = 2;
   */
  latitude = 0

  constructor(data?: PartialMessage<Coordinate>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.Coordinate'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'longitude', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: 'latitude', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Coordinate {
    return new Coordinate().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Coordinate {
    return new Coordinate().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Coordinate {
    return new Coordinate().fromJsonString(jsonString, options)
  }

  static equals(
    a: Coordinate | PlainMessage<Coordinate> | undefined,
    b: Coordinate | PlainMessage<Coordinate> | undefined
  ): boolean {
    return proto3.util.equals(Coordinate, a, b)
  }
}

/**
 * @generated from message analytics.v2.ScatterPlotCoordinate
 */
export class ScatterPlotCoordinate extends Message<ScatterPlotCoordinate> {
  /**
   * @generated from field: bool is_interstitial = 1;
   */
  isInterstitial = false

  /**
   * @generated from field: repeated float coordinates = 2;
   */
  coordinates: number[] = []

  constructor(data?: PartialMessage<ScatterPlotCoordinate>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.ScatterPlotCoordinate'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'is_interstitial', kind: 'scalar', T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: 'coordinates', kind: 'scalar', T: 2 /* ScalarType.FLOAT */, repeated: true }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): ScatterPlotCoordinate {
    return new ScatterPlotCoordinate().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScatterPlotCoordinate {
    return new ScatterPlotCoordinate().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): ScatterPlotCoordinate {
    return new ScatterPlotCoordinate().fromJsonString(jsonString, options)
  }

  static equals(
    a: ScatterPlotCoordinate | PlainMessage<ScatterPlotCoordinate> | undefined,
    b: ScatterPlotCoordinate | PlainMessage<ScatterPlotCoordinate> | undefined
  ): boolean {
    return proto3.util.equals(ScatterPlotCoordinate, a, b)
  }
}

/**
 * GeoJson
 *
 * @generated from message analytics.v2.GeoJson
 */
export class GeoJson extends Message<GeoJson> {
  /**
   * @generated from field: string data_type = 1;
   */
  dataType = ''

  /**
   * @generated from field: string name = 2;
   */
  name = ''

  /**
   * @generated from field: repeated analytics.v2.GeoDataFeature features = 3;
   */
  features: GeoDataFeature[] = []

  constructor(data?: PartialMessage<GeoJson>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.GeoJson'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'data_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'features', kind: 'message', T: GeoDataFeature, repeated: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoJson {
    return new GeoJson().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoJson {
    return new GeoJson().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoJson {
    return new GeoJson().fromJsonString(jsonString, options)
  }

  static equals(
    a: GeoJson | PlainMessage<GeoJson> | undefined,
    b: GeoJson | PlainMessage<GeoJson> | undefined
  ): boolean {
    return proto3.util.equals(GeoJson, a, b)
  }
}

/**
 * @generated from message analytics.v2.GeoDataFeature
 */
export class GeoDataFeature extends Message<GeoDataFeature> {
  /**
   * @generated from field: string feature_type = 1;
   */
  featureType = ''

  /**
   * @generated from field: bytes properties = 2;
   */
  properties = new Uint8Array(0)

  /**
   * @generated from field: analytics.v2.GeoGeometry geometry = 3;
   */
  geometry?: GeoGeometry

  constructor(data?: PartialMessage<GeoDataFeature>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.GeoDataFeature'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'feature_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'properties', kind: 'scalar', T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: 'geometry', kind: 'message', T: GeoGeometry }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoDataFeature {
    return new GeoDataFeature().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoDataFeature {
    return new GeoDataFeature().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoDataFeature {
    return new GeoDataFeature().fromJsonString(jsonString, options)
  }

  static equals(
    a: GeoDataFeature | PlainMessage<GeoDataFeature> | undefined,
    b: GeoDataFeature | PlainMessage<GeoDataFeature> | undefined
  ): boolean {
    return proto3.util.equals(GeoDataFeature, a, b)
  }
}

/**
 * @generated from message analytics.v2.GeoGeometry
 */
export class GeoGeometry extends Message<GeoGeometry> {
  /**
   * @generated from field: string geometry_type = 1;
   */
  geometryType = ''

  /**
   * @generated from field: bytes coordinates = 2;
   */
  coordinates = new Uint8Array(0)

  constructor(data?: PartialMessage<GeoGeometry>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.GeoGeometry'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'geometry_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'coordinates', kind: 'scalar', T: 12 /* ScalarType.BYTES */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoGeometry {
    return new GeoGeometry().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoGeometry {
    return new GeoGeometry().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoGeometry {
    return new GeoGeometry().fromJsonString(jsonString, options)
  }

  static equals(
    a: GeoGeometry | PlainMessage<GeoGeometry> | undefined,
    b: GeoGeometry | PlainMessage<GeoGeometry> | undefined
  ): boolean {
    return proto3.util.equals(GeoGeometry, a, b)
  }
}

/**
 * Pagination info
 *
 * @generated from message analytics.v2.Pagination
 */
export class Pagination extends Message<Pagination> {
  /**
   * @generated from field: int64 total_pages = 1;
   */
  totalPages = protoInt64.zero

  /**
   * @generated from field: int64 page_num = 2;
   */
  pageNum = protoInt64.zero

  constructor(data?: PartialMessage<Pagination>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.Pagination'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'total_pages', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: 'page_num', kind: 'scalar', T: 3 /* ScalarType.INT64 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Pagination {
    return new Pagination().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Pagination {
    return new Pagination().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Pagination {
    return new Pagination().fromJsonString(jsonString, options)
  }

  static equals(
    a: Pagination | PlainMessage<Pagination> | undefined,
    b: Pagination | PlainMessage<Pagination> | undefined
  ): boolean {
    return proto3.util.equals(Pagination, a, b)
  }
}

/**
 * Audience Insights
 *
 * @generated from message analytics.v2.CompetitiveBenchmarkingData
 */
export class CompetitiveBenchmarkingData extends Message<CompetitiveBenchmarkingData> {
  /**
   * @generated from field: google.protobuf.Timestamp start_time = 1;
   */
  startTime?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp

  /**
   * @generated from field: int32 user_scans = 3;
   */
  userScans = 0

  /**
   * @generated from field: int32 user_link_clicks = 4;
   */
  userLinkClicks = 0

  /**
   * @generated from field: int32 user_crm_collected = 5;
   */
  userCrmCollected = 0

  /**
   * @generated from field: int32 benchmark_scans = 6;
   */
  benchmarkScans = 0

  /**
   * @generated from field: int32 benchmark_link_clicks = 7;
   */
  benchmarkLinkClicks = 0

  /**
   * @generated from field: int32 benchmark_crm_collected = 8;
   */
  benchmarkCrmCollected = 0

  constructor(data?: PartialMessage<CompetitiveBenchmarkingData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.CompetitiveBenchmarkingData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'start_time', kind: 'message', T: Timestamp },
    { no: 2, name: 'end_time', kind: 'message', T: Timestamp },
    { no: 3, name: 'user_scans', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'user_link_clicks', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'user_crm_collected', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'benchmark_scans', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'benchmark_link_clicks', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: 'benchmark_crm_collected', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): CompetitiveBenchmarkingData {
    return new CompetitiveBenchmarkingData().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): CompetitiveBenchmarkingData {
    return new CompetitiveBenchmarkingData().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): CompetitiveBenchmarkingData {
    return new CompetitiveBenchmarkingData().fromJsonString(jsonString, options)
  }

  static equals(
    a: CompetitiveBenchmarkingData | PlainMessage<CompetitiveBenchmarkingData> | undefined,
    b: CompetitiveBenchmarkingData | PlainMessage<CompetitiveBenchmarkingData> | undefined
  ): boolean {
    return proto3.util.equals(CompetitiveBenchmarkingData, a, b)
  }
}

/**
 * Range summary
 *
 * @generated from message analytics.v2.RangeSummaryData
 */
export class RangeSummaryData extends Message<RangeSummaryData> {
  /**
   * @generated from field: int32 active_codes = 1;
   */
  activeCodes = 0

  /**
   * @generated from field: int32 active_pages = 2;
   */
  activePages = 0

  /**
   * @generated from field: int32 scans = 3;
   */
  scans = 0

  /**
   * @generated from field: int32 short_url_clicks = 4;
   */
  shortUrlClicks = 0

  /**
   * @generated from field: int32 taps = 5;
   */
  taps = 0

  /**
   * @generated from field: int32 page_views = 6;
   */
  pageViews = 0

  /**
   * @generated from field: int32 link_clicks = 7;
   */
  linkClicks = 0

  /**
   * @generated from field: int32 contacts = 8;
   */
  contacts = 0

  constructor(data?: PartialMessage<RangeSummaryData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.RangeSummaryData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'active_codes', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'active_pages', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'scans', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'short_url_clicks', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'taps', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'page_views', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'link_clicks', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: 'contacts', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RangeSummaryData {
    return new RangeSummaryData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RangeSummaryData {
    return new RangeSummaryData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RangeSummaryData {
    return new RangeSummaryData().fromJsonString(jsonString, options)
  }

  static equals(
    a: RangeSummaryData | PlainMessage<RangeSummaryData> | undefined,
    b: RangeSummaryData | PlainMessage<RangeSummaryData> | undefined
  ): boolean {
    return proto3.util.equals(RangeSummaryData, a, b)
  }
}

/**
 * Summary
 *
 * @generated from message analytics.v2.InnerSummary
 */
export class InnerSummary extends Message<InnerSummary> {
  /**
   * @generated from field: int32 current = 1;
   */
  current = 0

  /**
   * @generated from field: int32 previous = 2;
   */
  previous = 0

  /**
   * @generated from field: int32 current_unique = 3;
   */
  currentUnique = 0

  /**
   * @generated from field: int32 previous_unique = 4;
   */
  previousUnique = 0

  constructor(data?: PartialMessage<InnerSummary>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.InnerSummary'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'current', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'previous', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'current_unique', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'previous_unique', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InnerSummary {
    return new InnerSummary().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InnerSummary {
    return new InnerSummary().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InnerSummary {
    return new InnerSummary().fromJsonString(jsonString, options)
  }

  static equals(
    a: InnerSummary | PlainMessage<InnerSummary> | undefined,
    b: InnerSummary | PlainMessage<InnerSummary> | undefined
  ): boolean {
    return proto3.util.equals(InnerSummary, a, b)
  }
}

/**
 * @generated from message analytics.v2.SummaryData
 */
export class SummaryData extends Message<SummaryData> {
  /**
   * @generated from field: analytics.v2.InnerSummary today = 1;
   */
  today?: InnerSummary

  /**
   * @generated from field: analytics.v2.InnerSummary last_7_days = 2;
   */
  last7Days?: InnerSummary

  /**
   * @generated from field: analytics.v2.InnerSummary last_30_days = 3;
   */
  last30Days?: InnerSummary

  /**
   * @generated from field: analytics.v2.InnerSummary last_90_days = 4;
   */
  last90Days?: InnerSummary

  /**
   * @generated from field: analytics.v2.InnerSummary all_time = 5;
   */
  allTime?: InnerSummary

  constructor(data?: PartialMessage<SummaryData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.SummaryData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'today', kind: 'message', T: InnerSummary },
    { no: 2, name: 'last_7_days', kind: 'message', T: InnerSummary },
    { no: 3, name: 'last_30_days', kind: 'message', T: InnerSummary },
    { no: 4, name: 'last_90_days', kind: 'message', T: InnerSummary },
    { no: 5, name: 'all_time', kind: 'message', T: InnerSummary }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummaryData {
    return new SummaryData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryData {
    return new SummaryData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummaryData {
    return new SummaryData().fromJsonString(jsonString, options)
  }

  static equals(
    a: SummaryData | PlainMessage<SummaryData> | undefined,
    b: SummaryData | PlainMessage<SummaryData> | undefined
  ): boolean {
    return proto3.util.equals(SummaryData, a, b)
  }
}

/**
 * @generated from message analytics.v2.SummaryPixelsData
 */
export class SummaryPixelsData extends Message<SummaryPixelsData> {
  /**
   * @generated from field: int32 count = 1;
   */
  count = 0

  /**
   * @generated from field: int32 unique_count = 2;
   */
  uniqueCount = 0

  /**
   * @generated from field: int32 pixel_page_views = 3;
   */
  pixelPageViews = 0

  /**
   * @generated from field: int32 pixel_conversions = 4;
   */
  pixelConversions = 0

  /**
   * @generated from field: int32 pixel_unique_conversions = 5;
   */
  pixelUniqueConversions = 0

  constructor(data?: PartialMessage<SummaryPixelsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.SummaryPixelsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'unique_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'pixel_page_views', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'pixel_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'pixel_unique_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummaryPixelsData {
    return new SummaryPixelsData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryPixelsData {
    return new SummaryPixelsData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummaryPixelsData {
    return new SummaryPixelsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: SummaryPixelsData | PlainMessage<SummaryPixelsData> | undefined,
    b: SummaryPixelsData | PlainMessage<SummaryPixelsData> | undefined
  ): boolean {
    return proto3.util.equals(SummaryPixelsData, a, b)
  }
}

/**
 * Count
 *
 * @generated from message analytics.v2.FlowcodeCount
 */
export class FlowcodeCount extends Message<FlowcodeCount> {
  /**
   * @generated from field: string asset_id = 1;
   */
  assetId = ''

  /**
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero

  /**
   * @generated from field: int64 unique_users = 3;
   */
  uniqueUsers = protoInt64.zero

  constructor(data?: PartialMessage<FlowcodeCount>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.FlowcodeCount'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'count', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: 'unique_users', kind: 'scalar', T: 3 /* ScalarType.INT64 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlowcodeCount {
    return new FlowcodeCount().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlowcodeCount {
    return new FlowcodeCount().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlowcodeCount {
    return new FlowcodeCount().fromJsonString(jsonString, options)
  }

  static equals(
    a: FlowcodeCount | PlainMessage<FlowcodeCount> | undefined,
    b: FlowcodeCount | PlainMessage<FlowcodeCount> | undefined
  ): boolean {
    return proto3.util.equals(FlowcodeCount, a, b)
  }
}

/**
 * @generated from message analytics.v2.FlowpageCount
 */
export class FlowpageCount extends Message<FlowpageCount> {
  /**
   * @generated from field: string page_id = 1;
   */
  pageId = ''

  /**
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero

  /**
   * @generated from field: float clickthrough_rate = 3;
   */
  clickthroughRate = 0

  constructor(data?: PartialMessage<FlowpageCount>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.FlowpageCount'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'count', kind: 'scalar', T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: 'clickthrough_rate', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlowpageCount {
    return new FlowpageCount().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlowpageCount {
    return new FlowpageCount().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlowpageCount {
    return new FlowpageCount().fromJsonString(jsonString, options)
  }

  static equals(
    a: FlowpageCount | PlainMessage<FlowpageCount> | undefined,
    b: FlowpageCount | PlainMessage<FlowpageCount> | undefined
  ): boolean {
    return proto3.util.equals(FlowpageCount, a, b)
  }
}

/**
 * @generated from message analytics.v2.CountData
 */
export class CountData extends Message<CountData> {
  /**
   * @generated from field: map<string, analytics.v2.FlowcodeCount> flowcode_counts = 1;
   */
  flowcodeCounts: { [key: string]: FlowcodeCount } = {}

  /**
   * @generated from field: map<string, analytics.v2.FlowpageCount> flowpage_counts = 2;
   */
  flowpageCounts: { [key: string]: FlowpageCount } = {}

  constructor(data?: PartialMessage<CountData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.CountData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    {
      no: 1,
      name: 'flowcode_counts',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: FlowcodeCount }
    },
    {
      no: 2,
      name: 'flowpage_counts',
      kind: 'map',
      K: 9 /* ScalarType.STRING */,
      V: { kind: 'message', T: FlowpageCount }
    }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountData {
    return new CountData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountData {
    return new CountData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CountData {
    return new CountData().fromJsonString(jsonString, options)
  }

  static equals(
    a: CountData | PlainMessage<CountData> | undefined,
    b: CountData | PlainMessage<CountData> | undefined
  ): boolean {
    return proto3.util.equals(CountData, a, b)
  }
}

/**
 * Raw events
 *
 * @generated from message analytics.v2.RawEventsData
 */
export class RawEventsData extends Message<RawEventsData> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: google.protobuf.Timestamp event_time = 2;
   */
  eventTime?: Timestamp

  /**
   * @generated from field: string asset_id = 3;
   */
  assetId = ''

  /**
   * @generated from field: string asset_name = 4;
   */
  assetName = ''

  /**
   * @generated from field: string directory_name = 5;
   */
  directoryName = ''

  /**
   * @generated from field: analytics.v2.OperatingSystem operating_system = 6;
   */
  operatingSystem = OperatingSystem.UNSPECIFIED

  /**
   * @generated from field: analytics.v2.Device device = 7;
   */
  device = Device.UNSPECIFIED

  /**
   * @generated from field: analytics.v2.EventType event_type = 8;
   */
  eventType = EventType.UNSPECIFIED

  /**
   * @generated from field: string destination = 9;
   */
  destination = ''

  /**
   * @generated from field: string link_name = 10;
   */
  linkName = ''

  /**
   * @generated from field: string country = 11;
   */
  country = ''

  /**
   * @generated from field: string state = 12;
   */
  state = ''

  /**
   * @generated from field: string city = 13;
   */
  city = ''

  /**
   * @generated from field: string zip = 14;
   */
  zip = ''

  /**
   * @generated from field: float latitude = 15;
   */
  latitude = 0

  /**
   * @generated from field: float longitude = 16;
   */
  longitude = 0

  /**
   * @generated from field: string geo_type = 17;
   */
  geoType = ''

  /**
   * @generated from field: string app_id = 18;
   */
  appId = ''

  constructor(data?: PartialMessage<RawEventsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.RawEventsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'event_time', kind: 'message', T: Timestamp },
    { no: 3, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'asset_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'directory_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 6, name: 'operating_system', kind: 'enum', T: proto3.getEnumType(OperatingSystem) },
    { no: 7, name: 'device', kind: 'enum', T: proto3.getEnumType(Device) },
    { no: 8, name: 'event_type', kind: 'enum', T: proto3.getEnumType(EventType) },
    { no: 9, name: 'destination', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 10, name: 'link_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 11, name: 'country', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 12, name: 'state', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 13, name: 'city', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 14, name: 'zip', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 15, name: 'latitude', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
    { no: 16, name: 'longitude', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ },
    { no: 17, name: 'geo_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 18, name: 'app_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RawEventsData {
    return new RawEventsData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RawEventsData {
    return new RawEventsData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RawEventsData {
    return new RawEventsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: RawEventsData | PlainMessage<RawEventsData> | undefined,
    b: RawEventsData | PlainMessage<RawEventsData> | undefined
  ): boolean {
    return proto3.util.equals(RawEventsData, a, b)
  }
}

/**
 * Top Codes
 *
 * @generated from message analytics.v2.TopCodesData
 */
export class TopCodesData extends Message<TopCodesData> {
  /**
   * @generated from field: int32 count = 1;
   */
  count = 0

  /**
   * @generated from field: string asset_id = 2;
   */
  assetId = ''

  /**
   * @generated from field: string nickname = 3;
   */
  nickname = ''

  /**
   * @generated from field: int32 all_time_count = 4;
   */
  allTimeCount = 0

  /**
   * @generated from field: int32 previous_count = 5;
   */
  previousCount = 0

  constructor(data?: PartialMessage<TopCodesData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopCodesData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'nickname', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'all_time_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'previous_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopCodesData {
    return new TopCodesData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopCodesData {
    return new TopCodesData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopCodesData {
    return new TopCodesData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopCodesData | PlainMessage<TopCodesData> | undefined,
    b: TopCodesData | PlainMessage<TopCodesData> | undefined
  ): boolean {
    return proto3.util.equals(TopCodesData, a, b)
  }
}

/**
 * Top Codes Pixels
 *
 * @generated from message analytics.v2.TopCodesPixelsData
 */
export class TopCodesPixelsData extends Message<TopCodesPixelsData> {
  /**
   * @generated from field: string asset_id = 1;
   */
  assetId = ''

  /**
   * @generated from field: string nickname = 2;
   */
  nickname = ''

  /**
   * @generated from field: int32 count = 3;
   */
  count = 0

  /**
   * @generated from field: int32 unique_count = 4;
   */
  uniqueCount = 0

  /**
   * @generated from field: int32 pixel_page_views = 5;
   */
  pixelPageViews = 0

  /**
   * @generated from field: int32 pixel_conversions = 6;
   */
  pixelConversions = 0

  /**
   * @generated from field: int32 pixel_unique_conversions = 7;
   */
  pixelUniqueConversions = 0

  constructor(data?: PartialMessage<TopCodesPixelsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopCodesPixelsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'asset_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'nickname', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'unique_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'pixel_page_views', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'pixel_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'pixel_unique_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopCodesPixelsData {
    return new TopCodesPixelsData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopCodesPixelsData {
    return new TopCodesPixelsData().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopCodesPixelsData {
    return new TopCodesPixelsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopCodesPixelsData | PlainMessage<TopCodesPixelsData> | undefined,
    b: TopCodesPixelsData | PlainMessage<TopCodesPixelsData> | undefined
  ): boolean {
    return proto3.util.equals(TopCodesPixelsData, a, b)
  }
}

/**
 * Top Paths Pixels
 *
 * @generated from message analytics.v2.TopPathsPixelsData
 */
export class TopPathsPixelsData extends Message<TopPathsPixelsData> {
  /**
   * @generated from field: string path = 1;
   */
  path = ''

  /**
   * @generated from field: int32 pixel_page_views = 2;
   */
  pixelPageViews = 0

  /**
   * @generated from field: int32 pixel_conversions = 3;
   */
  pixelConversions = 0

  /**
   * @generated from field: int32 pixel_unique_conversions = 4;
   */
  pixelUniqueConversions = 0

  constructor(data?: PartialMessage<TopPathsPixelsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopPathsPixelsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'path', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'pixel_page_views', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'pixel_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'pixel_unique_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopPathsPixelsData {
    return new TopPathsPixelsData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopPathsPixelsData {
    return new TopPathsPixelsData().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopPathsPixelsData {
    return new TopPathsPixelsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopPathsPixelsData | PlainMessage<TopPathsPixelsData> | undefined,
    b: TopPathsPixelsData | PlainMessage<TopPathsPixelsData> | undefined
  ): boolean {
    return proto3.util.equals(TopPathsPixelsData, a, b)
  }
}

/**
 * Top Destinations
 *
 * @generated from message analytics.v2.TopDestinationsData
 */
export class TopDestinationsData extends Message<TopDestinationsData> {
  /**
   * @generated from field: int32 count = 1;
   */
  count = 0

  /**
   * @generated from field: string destination = 2;
   */
  destination = ''

  /**
   * @generated from field: int32 all_time_count = 3;
   */
  allTimeCount = 0

  /**
   * @generated from field: int32 previous_count = 4;
   */
  previousCount = 0

  constructor(data?: PartialMessage<TopDestinationsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopDestinationsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'destination', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'all_time_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'previous_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopDestinationsData {
    return new TopDestinationsData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopDestinationsData {
    return new TopDestinationsData().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopDestinationsData {
    return new TopDestinationsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopDestinationsData | PlainMessage<TopDestinationsData> | undefined,
    b: TopDestinationsData | PlainMessage<TopDestinationsData> | undefined
  ): boolean {
    return proto3.util.equals(TopDestinationsData, a, b)
  }
}

/**
 * Top Pages
 *
 * @generated from message analytics.v2.TopPagesData
 */
export class TopPagesData extends Message<TopPagesData> {
  /**
   * @generated from field: string page_id = 1;
   */
  pageId = ''

  /**
   * @generated from field: string page_title = 2;
   */
  pageTitle = ''

  /**
   * @generated from field: google.protobuf.Timestamp deleted_at = 3;
   */
  deletedAt?: Timestamp

  /**
   * @generated from field: int32 page_views_count = 4;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 5;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 contacts_collected_count = 6;
   */
  contactsCollectedCount = 0

  /**
   * @generated from field: int32 all_time_views_count = 7;
   */
  allTimeViewsCount = 0

  /**
   * @generated from field: int32 previous_page_views_count = 8;
   */
  previousPageViewsCount = 0

  /**
   * @generated from field: int32 all_time_clicks_count = 9;
   */
  allTimeClicksCount = 0

  /**
   * @generated from field: int32 previous_link_clicks_count = 10;
   */
  previousLinkClicksCount = 0

  /**
   * @generated from field: float clickthrough_rate = 11;
   */
  clickthroughRate = 0

  constructor(data?: PartialMessage<TopPagesData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopPagesData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'page_title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'deleted_at', kind: 'message', T: Timestamp },
    { no: 4, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'contacts_collected_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'all_time_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: 'previous_page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: 'all_time_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: 'previous_link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: 'clickthrough_rate', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopPagesData {
    return new TopPagesData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopPagesData {
    return new TopPagesData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopPagesData {
    return new TopPagesData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopPagesData | PlainMessage<TopPagesData> | undefined,
    b: TopPagesData | PlainMessage<TopPagesData> | undefined
  ): boolean {
    return proto3.util.equals(TopPagesData, a, b)
  }
}

/**
 * Top Links
 *
 * @generated from message analytics.v2.TopLinksData
 */
export class TopLinksData extends Message<TopLinksData> {
  /**
   * @generated from field: int32 link_clicks_count = 1;
   */
  linkClicksCount = 0

  /**
   * @generated from field: string link_id = 2;
   */
  linkId = ''

  /**
   * @generated from field: string link_title = 3;
   */
  linkTitle = ''

  /**
   * @generated from field: string link_type = 4;
   */
  linkType = ''

  /**
   * @generated from field: google.protobuf.Timestamp deleted_at = 5;
   */
  deletedAt?: Timestamp

  /**
   * @generated from field: string page_id = 6;
   */
  pageId = ''

  /**
   * @generated from field: string page_title = 7;
   */
  pageTitle = ''

  /**
   * @generated from field: google.protobuf.Timestamp page_deleted_at = 8;
   */
  pageDeletedAt?: Timestamp

  /**
   * @generated from field: int32 all_time_clicks_count = 9;
   */
  allTimeClicksCount = 0

  /**
   * @generated from field: int32 previous_link_clicks_count = 10;
   */
  previousLinkClicksCount = 0

  constructor(data?: PartialMessage<TopLinksData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopLinksData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'link_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'link_title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'link_type', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'deleted_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'page_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 7, name: 'page_title', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 8, name: 'page_deleted_at', kind: 'message', T: Timestamp },
    { no: 9, name: 'all_time_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: 'previous_link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopLinksData {
    return new TopLinksData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopLinksData {
    return new TopLinksData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopLinksData {
    return new TopLinksData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopLinksData | PlainMessage<TopLinksData> | undefined,
    b: TopLinksData | PlainMessage<TopLinksData> | undefined
  ): boolean {
    return proto3.util.equals(TopLinksData, a, b)
  }
}

/**
 * Top UTM Campaigns
 *
 * @generated from message analytics.v2.TopUTMCampaignsData
 */
export class TopUTMCampaignsData extends Message<TopUTMCampaignsData> {
  /**
   * @generated from field: string utm_campaign = 1;
   */
  utmCampaign = ''

  /**
   * @generated from field: string utm_term = 2;
   */
  utmTerm = ''

  /**
   * @generated from field: string utm_source = 3;
   */
  utmSource = ''

  /**
   * @generated from field: int32 page_views_count = 4;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 5;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 contacts_collected_count = 6;
   */
  contactsCollectedCount = 0

  /**
   * @generated from field: float clickthrough_rate = 7;
   */
  clickthroughRate = 0

  constructor(data?: PartialMessage<TopUTMCampaignsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopUTMCampaignsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'utm_campaign', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'utm_term', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'utm_source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'contacts_collected_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'clickthrough_rate', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopUTMCampaignsData {
    return new TopUTMCampaignsData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopUTMCampaignsData {
    return new TopUTMCampaignsData().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopUTMCampaignsData {
    return new TopUTMCampaignsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopUTMCampaignsData | PlainMessage<TopUTMCampaignsData> | undefined,
    b: TopUTMCampaignsData | PlainMessage<TopUTMCampaignsData> | undefined
  ): boolean {
    return proto3.util.equals(TopUTMCampaignsData, a, b)
  }
}

/**
 * Top UTM Terms
 *
 * @generated from message analytics.v2.TopUTMTermsData
 */
export class TopUTMTermsData extends Message<TopUTMTermsData> {
  /**
   * @generated from field: string utm_campaign = 1;
   */
  utmCampaign = ''

  /**
   * @generated from field: string utm_term = 2;
   */
  utmTerm = ''

  /**
   * @generated from field: string utm_source = 3;
   */
  utmSource = ''

  /**
   * @generated from field: int32 page_views_count = 4;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 5;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 contacts_collected_count = 6;
   */
  contactsCollectedCount = 0

  /**
   * @generated from field: float clickthrough_rate = 7;
   */
  clickthroughRate = 0

  constructor(data?: PartialMessage<TopUTMTermsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopUTMTermsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'utm_campaign', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'utm_term', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'utm_source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'contacts_collected_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'clickthrough_rate', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopUTMTermsData {
    return new TopUTMTermsData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopUTMTermsData {
    return new TopUTMTermsData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopUTMTermsData {
    return new TopUTMTermsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopUTMTermsData | PlainMessage<TopUTMTermsData> | undefined,
    b: TopUTMTermsData | PlainMessage<TopUTMTermsData> | undefined
  ): boolean {
    return proto3.util.equals(TopUTMTermsData, a, b)
  }
}

/**
 * Top UTM Referrers
 *
 * @generated from message analytics.v2.TopUTMReferrersData
 */
export class TopUTMReferrersData extends Message<TopUTMReferrersData> {
  /**
   * @generated from field: string utm_campaign = 1;
   */
  utmCampaign = ''

  /**
   * @generated from field: string utm_term = 2;
   */
  utmTerm = ''

  /**
   * @generated from field: string utm_source = 3;
   */
  utmSource = ''

  /**
   * @generated from field: int32 page_views_count = 4;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 5;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 contacts_collected_count = 6;
   */
  contactsCollectedCount = 0

  /**
   * @generated from field: float clickthrough_rate = 7;
   */
  clickthroughRate = 0

  constructor(data?: PartialMessage<TopUTMReferrersData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopUTMReferrersData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'utm_campaign', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'utm_term', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'utm_source', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'contacts_collected_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'clickthrough_rate', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopUTMReferrersData {
    return new TopUTMReferrersData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopUTMReferrersData {
    return new TopUTMReferrersData().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopUTMReferrersData {
    return new TopUTMReferrersData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopUTMReferrersData | PlainMessage<TopUTMReferrersData> | undefined,
    b: TopUTMReferrersData | PlainMessage<TopUTMReferrersData> | undefined
  ): boolean {
    return proto3.util.equals(TopUTMReferrersData, a, b)
  }
}

/**
 * Top Cities
 *
 * @generated from message analytics.v2.TopCitiesData
 */
export class TopCitiesData extends Message<TopCitiesData> {
  /**
   * @generated from field: string postal_code = 1;
   */
  postalCode = ''

  /**
   * @generated from field: string city = 2;
   */
  city = ''

  /**
   * @generated from field: string state = 3;
   */
  state = ''

  /**
   * @generated from field: string country = 4;
   */
  country = ''

  /**
   * @generated from field: int32 page_views_count = 5;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 6;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 count = 7;
   */
  count = 0

  constructor(data?: PartialMessage<TopCitiesData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopCitiesData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'postal_code', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'city', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'state', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'country', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopCitiesData {
    return new TopCitiesData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopCitiesData {
    return new TopCitiesData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopCitiesData {
    return new TopCitiesData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopCitiesData | PlainMessage<TopCitiesData> | undefined,
    b: TopCitiesData | PlainMessage<TopCitiesData> | undefined
  ): boolean {
    return proto3.util.equals(TopCitiesData, a, b)
  }
}

/**
 * Top States
 *
 * @generated from message analytics.v2.TopStatesData
 */
export class TopStatesData extends Message<TopStatesData> {
  /**
   * @generated from field: string state = 1;
   */
  state = ''

  /**
   * @generated from field: string country = 2;
   */
  country = ''

  /**
   * @generated from field: int32 page_views_count = 3;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 4;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 count = 5;
   */
  count = 0

  constructor(data?: PartialMessage<TopStatesData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopStatesData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'state', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'country', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopStatesData {
    return new TopStatesData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopStatesData {
    return new TopStatesData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopStatesData {
    return new TopStatesData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopStatesData | PlainMessage<TopStatesData> | undefined,
    b: TopStatesData | PlainMessage<TopStatesData> | undefined
  ): boolean {
    return proto3.util.equals(TopStatesData, a, b)
  }
}

/**
 * Top Days
 *
 * @generated from message analytics.v2.TopDaysData
 */
export class TopDaysData extends Message<TopDaysData> {
  /**
   * @generated from field: int32 day = 1;
   */
  day = 0

  /**
   * @generated from field: int32 page_views_count = 2;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 3;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 count = 4;
   */
  count = 0

  constructor(data?: PartialMessage<TopDaysData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopDaysData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'day', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopDaysData {
    return new TopDaysData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopDaysData {
    return new TopDaysData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopDaysData {
    return new TopDaysData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopDaysData | PlainMessage<TopDaysData> | undefined,
    b: TopDaysData | PlainMessage<TopDaysData> | undefined
  ): boolean {
    return proto3.util.equals(TopDaysData, a, b)
  }
}

/**
 * Top Hours
 *
 * @generated from message analytics.v2.TopHoursData
 */
export class TopHoursData extends Message<TopHoursData> {
  /**
   * @generated from field: int32 hour = 1;
   */
  hour = 0

  /**
   * @generated from field: int32 page_views_count = 2;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 3;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 count = 4;
   */
  count = 0

  constructor(data?: PartialMessage<TopHoursData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopHoursData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'hour', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopHoursData {
    return new TopHoursData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopHoursData {
    return new TopHoursData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopHoursData {
    return new TopHoursData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopHoursData | PlainMessage<TopHoursData> | undefined,
    b: TopHoursData | PlainMessage<TopHoursData> | undefined
  ): boolean {
    return proto3.util.equals(TopHoursData, a, b)
  }
}

/**
 * Top Devices
 *
 * @generated from message analytics.v2.TopDevicesData
 */
export class TopDevicesData extends Message<TopDevicesData> {
  /**
   * @generated from field: analytics.v2.Device device = 1;
   */
  device = Device.UNSPECIFIED

  /**
   * @generated from field: int32 page_views_count = 2;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 3;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 count = 4;
   */
  count = 0

  constructor(data?: PartialMessage<TopDevicesData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopDevicesData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'device', kind: 'enum', T: proto3.getEnumType(Device) },
    { no: 2, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopDevicesData {
    return new TopDevicesData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopDevicesData {
    return new TopDevicesData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopDevicesData {
    return new TopDevicesData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopDevicesData | PlainMessage<TopDevicesData> | undefined,
    b: TopDevicesData | PlainMessage<TopDevicesData> | undefined
  ): boolean {
    return proto3.util.equals(TopDevicesData, a, b)
  }
}

/**
 * Top Operating Systems
 *
 * @generated from message analytics.v2.TopOperatingSystemsData
 */
export class TopOperatingSystemsData extends Message<TopOperatingSystemsData> {
  /**
   * @generated from field: analytics.v2.OperatingSystem operating_system = 1;
   */
  operatingSystem = OperatingSystem.UNSPECIFIED

  /**
   * @generated from field: int32 page_views_count = 2;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 3;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 count = 4;
   */
  count = 0

  constructor(data?: PartialMessage<TopOperatingSystemsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopOperatingSystemsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'operating_system', kind: 'enum', T: proto3.getEnumType(OperatingSystem) },
    { no: 2, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): TopOperatingSystemsData {
    return new TopOperatingSystemsData().fromBinary(bytes, options)
  }

  static fromJson(
    jsonValue: JsonValue,
    options?: Partial<JsonReadOptions>
  ): TopOperatingSystemsData {
    return new TopOperatingSystemsData().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): TopOperatingSystemsData {
    return new TopOperatingSystemsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopOperatingSystemsData | PlainMessage<TopOperatingSystemsData> | undefined,
    b: TopOperatingSystemsData | PlainMessage<TopOperatingSystemsData> | undefined
  ): boolean {
    return proto3.util.equals(TopOperatingSystemsData, a, b)
  }
}

/**
 * Top Tags
 *
 * @generated from message analytics.v2.TopTagsData
 */
export class TopTagsData extends Message<TopTagsData> {
  /**
   * @generated from field: string tag_name = 1;
   */
  tagName = ''

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0

  /**
   * @generated from field: int32 page_views_count = 3;
   */
  pageViewsCount = 0

  /**
   * @generated from field: int32 link_clicks_count = 4;
   */
  linkClicksCount = 0

  /**
   * @generated from field: int32 contacts_collected_count = 5;
   */
  contactsCollectedCount = 0

  /**
   * @generated from field: int32 all_time_count = 6;
   */
  allTimeCount = 0

  /**
   * @generated from field: int32 previous_count = 7;
   */
  previousCount = 0

  /**
   * @generated from field: float clickthrough_rate = 8;
   */
  clickthroughRate = 0

  constructor(data?: PartialMessage<TopTagsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.TopTagsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'tag_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'page_views_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'link_clicks_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'contacts_collected_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'all_time_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'previous_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: 'clickthrough_rate', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopTagsData {
    return new TopTagsData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopTagsData {
    return new TopTagsData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopTagsData {
    return new TopTagsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: TopTagsData | PlainMessage<TopTagsData> | undefined,
    b: TopTagsData | PlainMessage<TopTagsData> | undefined
  ): boolean {
    return proto3.util.equals(TopTagsData, a, b)
  }
}

/**
 * Date range
 *
 * @generated from message analytics.v2.DateRangeData
 */
export class DateRangeData extends Message<DateRangeData> {
  /**
   * @generated from field: google.protobuf.Timestamp start_time = 1;
   */
  startTime?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp

  /**
   * @generated from field: int32 hits = 3;
   */
  hits = 0

  /**
   * @generated from field: int32 unique_hits = 4;
   */
  uniqueHits = 0

  /**
   * @generated from field: int32 page_views = 5;
   */
  pageViews = 0

  /**
   * @generated from field: int32 link_clicks = 6;
   */
  linkClicks = 0

  /**
   * @generated from field: int32 contacts = 7;
   */
  contacts = 0

  constructor(data?: PartialMessage<DateRangeData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DateRangeData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'start_time', kind: 'message', T: Timestamp },
    { no: 2, name: 'end_time', kind: 'message', T: Timestamp },
    { no: 3, name: 'hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'unique_hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'page_views', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'link_clicks', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'contacts', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateRangeData {
    return new DateRangeData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateRangeData {
    return new DateRangeData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DateRangeData {
    return new DateRangeData().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRangeData | PlainMessage<DateRangeData> | undefined,
    b: DateRangeData | PlainMessage<DateRangeData> | undefined
  ): boolean {
    return proto3.util.equals(DateRangeData, a, b)
  }
}

/**
 * @generated from message analytics.v2.DateRangeTotals
 */
export class DateRangeTotals extends Message<DateRangeTotals> {
  /**
   * @generated from field: int32 hits = 1;
   */
  hits = 0

  /**
   * @generated from field: int32 unique_hits = 2;
   */
  uniqueHits = 0

  /**
   * @generated from field: int32 page_views = 3;
   */
  pageViews = 0

  /**
   * @generated from field: int32 link_clicks = 4;
   */
  linkClicks = 0

  /**
   * @generated from field: int32 contacts = 5;
   */
  contacts = 0

  /**
   * @generated from field: int32 previous_hits = 6;
   */
  previousHits = 0

  /**
   * @generated from field: int32 previous_unique_hits = 7;
   */
  previousUniqueHits = 0

  /**
   * @generated from field: int32 previous_page_views = 8;
   */
  previousPageViews = 0

  /**
   * @generated from field: int32 previous_link_clicks = 9;
   */
  previousLinkClicks = 0

  /**
   * @generated from field: int32 previous_contacts = 10;
   */
  previousContacts = 0

  /**
   * @generated from field: google.protobuf.Timestamp cap_exceeded_date = 11;
   */
  capExceededDate?: Timestamp

  /**
   * @generated from field: float clickthrough_rate = 12;
   */
  clickthroughRate = 0

  constructor(data?: PartialMessage<DateRangeTotals>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DateRangeTotals'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'unique_hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'page_views', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'link_clicks', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'contacts', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'previous_hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'previous_unique_hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: 'previous_page_views', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: 'previous_link_clicks', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: 'previous_contacts', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: 'cap_exceeded_date', kind: 'message', T: Timestamp },
    { no: 12, name: 'clickthrough_rate', kind: 'scalar', T: 2 /* ScalarType.FLOAT */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateRangeTotals {
    return new DateRangeTotals().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateRangeTotals {
    return new DateRangeTotals().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DateRangeTotals {
    return new DateRangeTotals().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRangeTotals | PlainMessage<DateRangeTotals> | undefined,
    b: DateRangeTotals | PlainMessage<DateRangeTotals> | undefined
  ): boolean {
    return proto3.util.equals(DateRangeTotals, a, b)
  }
}

/**
 * Date Range Pixels
 *
 * @generated from message analytics.v2.DateRangePixelsData
 */
export class DateRangePixelsData extends Message<DateRangePixelsData> {
  /**
   * @generated from field: google.protobuf.Timestamp start_time = 1;
   */
  startTime?: Timestamp

  /**
   * @generated from field: google.protobuf.Timestamp end_time = 2;
   */
  endTime?: Timestamp

  /**
   * @generated from field: int32 hits = 3;
   */
  hits = 0

  /**
   * @generated from field: int32 unique_hits = 4;
   */
  uniqueHits = 0

  /**
   * @generated from field: int32 pixel_page_views = 5;
   */
  pixelPageViews = 0

  /**
   * @generated from field: int32 pixel_conversions = 6;
   */
  pixelConversions = 0

  /**
   * @generated from field: int32 pixel_unique_conversions = 7;
   */
  pixelUniqueConversions = 0

  constructor(data?: PartialMessage<DateRangePixelsData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DateRangePixelsData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'start_time', kind: 'message', T: Timestamp },
    { no: 2, name: 'end_time', kind: 'message', T: Timestamp },
    { no: 3, name: 'hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'unique_hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'pixel_page_views', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: 'pixel_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: 'pixel_unique_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DateRangePixelsData {
    return new DateRangePixelsData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateRangePixelsData {
    return new DateRangePixelsData().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DateRangePixelsData {
    return new DateRangePixelsData().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRangePixelsData | PlainMessage<DateRangePixelsData> | undefined,
    b: DateRangePixelsData | PlainMessage<DateRangePixelsData> | undefined
  ): boolean {
    return proto3.util.equals(DateRangePixelsData, a, b)
  }
}

/**
 * @generated from message analytics.v2.DateRangePixelsTotals
 */
export class DateRangePixelsTotals extends Message<DateRangePixelsTotals> {
  /**
   * @generated from field: int32 hits = 1;
   */
  hits = 0

  /**
   * @generated from field: int32 unique_hits = 2;
   */
  uniqueHits = 0

  /**
   * @generated from field: int32 pixel_page_views = 3;
   */
  pixelPageViews = 0

  /**
   * @generated from field: int32 pixel_conversions = 4;
   */
  pixelConversions = 0

  /**
   * @generated from field: int32 pixel_unique_conversions = 5;
   */
  pixelUniqueConversions = 0

  constructor(data?: PartialMessage<DateRangePixelsTotals>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.DateRangePixelsTotals'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: 'unique_hits', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'pixel_page_views', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'pixel_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: 'pixel_unique_conversions', kind: 'scalar', T: 5 /* ScalarType.INT32 */ }
  ])

  static fromBinary(
    bytes: Uint8Array,
    options?: Partial<BinaryReadOptions>
  ): DateRangePixelsTotals {
    return new DateRangePixelsTotals().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DateRangePixelsTotals {
    return new DateRangePixelsTotals().fromJson(jsonValue, options)
  }

  static fromJsonString(
    jsonString: string,
    options?: Partial<JsonReadOptions>
  ): DateRangePixelsTotals {
    return new DateRangePixelsTotals().fromJsonString(jsonString, options)
  }

  static equals(
    a: DateRangePixelsTotals | PlainMessage<DateRangePixelsTotals> | undefined,
    b: DateRangePixelsTotals | PlainMessage<DateRangePixelsTotals> | undefined
  ): boolean {
    return proto3.util.equals(DateRangePixelsTotals, a, b)
  }
}

/**
 * @generated from message analytics.v2.OrgActivityData
 */
export class OrgActivityData extends Message<OrgActivityData> {
  /**
   * @generated from field: string org_id = 1;
   */
  orgId = ''

  /**
   * @generated from field: int32 activity_count = 2;
   */
  activityCount = 0

  /**
   * @generated from field: int32 data_cap = 3;
   */
  dataCap = 0

  /**
   * @generated from field: google.protobuf.Timestamp cap_exceeded_date = 4;
   */
  capExceededDate?: Timestamp

  constructor(data?: PartialMessage<OrgActivityData>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'analytics.v2.OrgActivityData'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'org_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'activity_count', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: 'data_cap', kind: 'scalar', T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: 'cap_exceeded_date', kind: 'message', T: Timestamp }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgActivityData {
    return new OrgActivityData().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgActivityData {
    return new OrgActivityData().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgActivityData {
    return new OrgActivityData().fromJsonString(jsonString, options)
  }

  static equals(
    a: OrgActivityData | PlainMessage<OrgActivityData> | undefined,
    b: OrgActivityData | PlainMessage<OrgActivityData> | undefined
  ): boolean {
    return proto3.util.equals(OrgActivityData, a, b)
  }
}
