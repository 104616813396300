// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension="
// @generated from file pages/v1/page.proto (package pages.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type {
  BinaryReadOptions,
  FieldList,
  JsonReadOptions,
  JsonValue,
  PartialMessage,
  PlainMessage
} from '@bufbuild/protobuf'
import { Message, proto3, Timestamp } from '@bufbuild/protobuf'

/**
 * @generated from message pages.v1.Page
 */
export class Page extends Message<Page> {
  /**
   * @generated from field: string id = 1;
   */
  id = ''

  /**
   * @generated from field: string domain = 2;
   */
  domain = ''

  /**
   * @generated from field: string slug_name = 3;
   */
  slugName = ''

  /**
   * @generated from field: string code_id = 4;
   */
  codeId = ''

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp

  /**
   * @generated from field: optional google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp

  constructor(data?: PartialMessage<Page>) {
    super()
    proto3.util.initPartial(data, this)
  }

  static readonly runtime: typeof proto3 = proto3
  static readonly typeName = 'pages.v1.Page'
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: 'id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 2, name: 'domain', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 3, name: 'slug_name', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 4, name: 'code_id', kind: 'scalar', T: 9 /* ScalarType.STRING */ },
    { no: 5, name: 'created_at', kind: 'message', T: Timestamp },
    { no: 6, name: 'updated_at', kind: 'message', T: Timestamp, opt: true }
  ])

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Page {
    return new Page().fromBinary(bytes, options)
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Page {
    return new Page().fromJson(jsonValue, options)
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Page {
    return new Page().fromJsonString(jsonString, options)
  }

  static equals(
    a: Page | PlainMessage<Page> | undefined,
    b: Page | PlainMessage<Page> | undefined
  ): boolean {
    return proto3.util.equals(Page, a, b)
  }
}
