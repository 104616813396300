export const getBaseUrl = (
  service: string,
  appEnvironment?: 'development' | 'staging' | 'preprod' | 'production'
): string => {
  let environment = ''
  switch (appEnvironment) {
    case 'development':
      environment = '.stg'
      break
    case 'staging':
      environment = '.stg'
      break
    case 'preprod':
      environment = '.preprod'
      break
  }

  return `https://gateway${environment}.flowcode.com/${service}`
}
